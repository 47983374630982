// react
import { SyntheticEvent, useCallback, useContext, useState } from 'react';

// types
import { Entity, Loan, Project, User } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextProject } from '../../pages/Project/Project.context';
import { ContextUser } from '../../../App.context';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Box, Grid, Paper, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

// ui
import { BlockchainHash } from '../../ui/BlockchainHash/BlockchainHash';
import { BtnExpand } from '../../ui/BtnExpand/BtnExpand';
import { BtnMenu } from '../../ui/BtnMenu/BtnMenu';
import { ChipStatus } from '../../ui/ChipStatus/ChipStatus';
import { ChipTraceability } from '../../ui/ChipTraceability/ChipTraceability';
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { MenuItemProps } from '../../ui/Menu/Menu';
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Time } from '../../ui/Time/Time';

// links
import { LinkEntity } from '../../links/LinkEntity/LinkEntity';

// icons
import {
	ModeEditOutlined as IconModeEdit,
	DoDisturbOutlined as IconDoDisturb,
	PowerSettingsNewOutlined as IconPowerSettingsNew,
} from '@mui/icons-material';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// theme
import { theme } from '../../../theme';

// dialogs
import { DialogEditEntity } from '../../dialogs/DialogEditEntity/DialogEditEntity';
import { DialogUpdateEntityStatus } from '../../dialogs/DialogUpdateEntityStatus/DialogUpdateEntityStatus';

// tables
import { TableBasicUsers } from '../../tables/basic/TableBasicUsers/TableBasicUsers';
import { TableBasicFinancing } from '../../tables/basic/TableBasicFinancing/TableBasicFinancing';

// utils
import { translateType } from '../../../utils/translations';

// props
export interface CardEntityProps {
	entity: Entity;
	project: Project;
	refetchEntities?: () => void;
}

export const CardEntity = ({ entity, project, refetchEntities }: CardEntityProps) => {
	// hooks
	const { t } = useTranslation();

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [isExpanded, setIsExpanded] = useState(false);
	const [loans, setLoans] = useState<Loan[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [tabValue, setTabValue] = useState('financing');
	const [users, setUsers] = useState<User[]>(entity.users ? (entity.users as User[]) : []);

	// context
	const { isOnboarded: isProjectOnboarded } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// params
	const paramDialog = searchParams.get('dialog');
	const paramDialogEntity = searchParams.get('dialogEntity');

	// fetch loans
	useFetch({
		isEnabled: Boolean(entity.id),
		url: `${process.env.REACT_APP_API_URL}/loans?entity=${entity.id}&fields=id,idWbg,accounts,entities&populate=accounts,entities`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch users
	useFetch({
		isEnabled: isProjectOnboarded,
		url: `${process.env.REACT_APP_API_URL}/users?entity=${entity.id}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setUsers(res.data);
			}
		},
	});

	// handlers
	const handleToggleCard = () => {
		setIsExpanded(!isExpanded);
	};

	const handleOpenDialogUpdateEntity = useCallback(() => {
		searchParams.set('dialog', 'updateEntityStatus');
		searchParams.set('dialogEntity', String(entity.id));
		setSearchParams(searchParams);
	}, [entity, searchParams, setSearchParams]);

	const handleOpenDialogEditEntity = useCallback(() => {
		searchParams.set('dialog', 'editEntity');
		searchParams.set('dialogEntity', String(entity.id));
		setSearchParams(searchParams);
	}, [entity, searchParams, setSearchParams]);

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// vars
	const btnMenuItems: MenuItemProps[] = [];

	if (user?.permissions?.entity?.create) {
		btnMenuItems.push({
			children: t('edit'),
			icon: <IconModeEdit />,
			onClick: handleOpenDialogEditEntity,
		});
	}

	if (user?.permissions?.entity?.update) {
		let btnLabel = t('deactivate');
		let btnIcon = <IconDoDisturb color="error" />;

		if (entity.wallet?.status === 'INACTIVE') {
			btnLabel = t('activate');
			btnIcon = <IconPowerSettingsNew color="success" />;
		}

		btnMenuItems.push({
			children: btnLabel,
			icon: btnIcon,
			onClick: handleOpenDialogUpdateEntity,
		});
	}

	const data = loans.map((loan) => {
		const entityActive = loan.entities.find((e: Entity) => e.id === entity.id);
		return {
			...loan,
			parent: entityActive ? entityActive.parent : null,
		};
	});

	const tabs = [
		{
			label: t('financing'),
			value: 'financing',
		},
		{
			label: t('users'),
			value: 'users',
		},
	];

	return (
		<>
			<Paper
				sx={(theme) => {
					const borderColor = isExpanded ? theme.palette.brand.blue[500] : 'transparent';
					return {
						border: `1px ${borderColor} solid`,
						position: 'relative',
					};
				}}>
				<Box sx={{ padding: theme.spacing(2) }}>
					<Grid container alignItems={'center'} spacing={{ xs: 1, md: 2 }}>
						<Grid item xs={12} lg={3}>
							<Box
								sx={{
									margin: theme.spacing(0, 0, 2, 0),
									[theme.breakpoints.up('lg')]: {
										margin: 0,
									},
								}}>
								<Grid alignItems="center" container spacing={2} wrap="nowrap">
									<Grid item>
										<IconCircle type="entity" />
									</Grid>
									<Grid item>
										<LinkEntity entity={entity} />
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('wallet')}
								value={
									entity.wallet?.address ? <BlockchainHash hash={entity.wallet.address} /> : t('na')
								}
							/>
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue
								label={t('created')}
								value={entity.created ? <Time date={new Date(entity.created)} /> : t('na')}
							/>
						</Grid>
						<Grid item xs={12} lg={1}>
							<RowKeyValue
								label={t('status')}
								value={
									<ChipStatus
										status={isProjectOnboarded ? entity.wallet?.status || 'ACTIVE' : 'PENDING'}
									/>
								}
							/>
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue
								label={t('type')}
								value={
									<Typography component="div">
										{entity.type ? translateType(entity.type, t) : t('na')}
									</Typography>
								}
							/>
						</Grid>
						<Grid item xs={12} lg={1}>
							<RowKeyValue
								label={t('traceability')}
								value={<ChipTraceability traceability={entity.traceability || 0} />}
							/>
						</Grid>

						<Grid item xs={12} lg={true}>
							<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={1}>
								{isProjectOnboarded && btnMenuItems.length > 0 && (
									<Grid
										item
										sx={{
											position: 'absolute',
											top: 0,
											right: theme.spacing(1),
											[theme.breakpoints.up('lg')]: {
												position: 'relative',
												top: 'auto',
												right: 'auto',
											},
										}}>
										<BtnMenu items={btnMenuItems} />
									</Grid>
								)}
								<Grid item>
									<BtnExpand
										isExpanded={isExpanded}
										label={isExpanded ? t('hideLoans') : t('seeLoans')}
										onClick={handleToggleCard}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				{isExpanded && (
					<TabContext value={tabValue}>
						<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
						<TabPanel value="financing">
							<TableBasicFinancing data={data} />
						</TabPanel>
						<TabPanel value="users">
							<TableBasicUsers data={users} />
						</TabPanel>
					</TabContext>
				)}
			</Paper>
			<DialogUpdateEntityStatus
				entity={entity}
				isOpen={paramDialog === 'updateEntityStatus' && paramDialogEntity === String(entity.id)}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogEntity');
					setSearchParams(searchParams);
				}}
				refetchEntities={refetchEntities}
			/>
			<DialogEditEntity
				entity={entity}
				isOpen={paramDialog === 'editEntity' && paramDialogEntity === String(entity.id)}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogEntity');
					setSearchParams(searchParams);
				}}
			/>
		</>
	);
};
