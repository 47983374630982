// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// types
import { Contract, Entity, ErrorCause } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';
import { Select } from '../../fields/Select/Select';
import { SelectCurrency } from '../../fields/SelectCurrency/SelectCurrency';

// props
export interface FormManageContractProps {
	contract?: Contract;
	onClose?: () => void;
	refetchContracts?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	supplier?: Entity;
}

export const FormManageContract = ({
	contract,
	onClose,
	refetchContracts,
	setIsLoading,
	supplier,
}: FormManageContractProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { project } = useContext(ContextProject);
	const { setBanner } = useContext(ContextBanner);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [error, setError] = useState<string | null>(null);
	const [suppliers, setSuppliers] = useState<Entity[]>([]);

	// vars
	let contractSupplier: Entity | number | undefined;
	if (typeof contract?.supplier === 'number') {
		contractSupplier = contract?.supplier;
	} else {
		contractSupplier = contract?.supplier.id;
	}
	const idForm = 'form-manage-contract';
	const isEdit = Boolean(contract);
	let supplierUrl = `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&type=SUPPLIER`;
	if (supplier) {
		supplierUrl = `${process.env.REACT_APP_API_URL}/entities/${supplier.id}?project=${project?.id}&fields=id,name`;
	}

	// fetch suppliers
	useFetch({
		isEnabled: Boolean(project?.id),
		url: supplierUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setSuppliers(res.data);
			}
		},
	});

	// options
	const optionsSuppliers = suppliers.map((supplier) => {
		return {
			label: supplier.name,
			value: supplier.id,
		};
	});

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// set loader
			setIsLoading(true);

			const entrySupplier = supplier?.id || Number(entries.supplier);

			if (!entrySupplier) {
				throw new Error('A contract must be associated with a supplier.', {
					cause: {
						id: 'CONTRACT_SUPPLIER_EMPTY',
					},
				});
			}

			const body = {
				amount: Number(entries.amount),
				currency: entries.currency,
				num: entries.num,
				project: project?.id,
				idStep: entries.idStep,
				supplier: entrySupplier,
			};

			let url = `${process.env.REACT_APP_API_URL}/contracts`;
			if (contract) {
				url += `/${contract.id}`;
			}

			const fetchAddContract = await fetch(url, {
				method: isEdit ? 'PATCH' : 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
				body: JSON.stringify(body),
			});
			const resAddContract = await fetchAddContract.json();

			if (resAddContract.error) {
				throw new Error(resAddContract.error.message, {
					cause: {
						id: resAddContract.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			// refetch
			if (refetchContracts) {
				refetchContracts();
			}

			// banner message
			let bannerMessage = t('alert.contractCreated', { num: entries.num });
			if (isEdit) {
				bannerMessage = t('alert.contractUpdated', { num: entries.num });
			}
			// set success banner
			setBanner({
				hasClose: true,
				message: bannerMessage,
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause.id === 'CONTRACT_DUPLICATE') {
				message = t('error.contractDuplicate', { num: entries.num });
			}

			if (cause.id === 'CONTRACT_ID_NON_EXISTENT') {
				message = t('error.contractIdNonExistent');
			}

			if (cause.id === 'CONTRACT_NON_EXISTENT') {
				message = t('error.contractNonExistent', { id: contract?.id });
			}

			if (cause.id === 'CONTRACT_SUPPLIER_EMPTY') {
				message = t('error.contractSupplierEmpty');
			}

			if (cause.id === 'CONTRACT_UNAUTHORIZED_POST') {
				message = t('error.unauthorized');
			}

			// set error
			setError(message);
		} finally {
			// set loader
			setIsLoading(false);
		}
	};

	return (
		<form id={`${idForm}`} name="formAddContract" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={contract?.num}
						id={`${idForm}-num`}
						label={t('referenceNumber')}
						name="num"
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Select
						defaultValue={supplier?.id || contractSupplier}
						disabled={Boolean(supplier)}
						id={`${idForm}-supplier`}
						label={t('supplier')}
						name="supplier"
						options={optionsSuppliers}
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={contract?.amount}
						id={`${idForm}-amount`}
						label={t('amount')}
						name="amount"
						required={true}
						type="number"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<SelectCurrency
						defaultValue={contract?.currency}
						id={`${idForm}-currency`}
						name="currency"
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={contract?.idStep}
						id={`${idForm}-step-id`}
						label={t('stepId')}
						name="idStep"
						required={false}
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
