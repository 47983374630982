import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	btnClose: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(1),
		[`${theme.breakpoints.up('lg')}`]: {
			display: 'none',
		},
	},
	btnItem: {
		transition: 'all 200ms',
		borderRadius: 8,
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(1),
		padding: theme.spacing(1),
		width: '100%',
		textDecoration: 'none',
		color: theme.palette.common.white,
		'& svg': {
			fill: theme.palette.brand.blue[400],
			[`${theme.breakpoints.up('lg')}`]: {
				fill: theme.palette.common.white,
			},
		},
		'&:hover, &.active': {
			backgroundColor: theme.palette.brand.blue[600],
		},
		[`${theme.breakpoints.up('lg')}`]: {
			justifyContent: 'center',
		},
	},
	btnItemExpanded: {
		justifyContent: 'flex-start',
	},
	btnItemText: {
		[`${theme.breakpoints.up('lg')}`]: {
			display: 'none',
		},
	},
	btnItemTextExpanded: {
		display: 'inline-flex',
	},
	btnItemToggle: {
		display: 'none',
		[`${theme.breakpoints.up('lg')}`]: {
			display: 'inline-flex',
		},
	},
	drawer: {
		transition: 'all 200ms',
		transform: 'translateX(-100%)',
		backgroundColor: theme.palette.secondary.main,
		position: 'fixed',
		top: 0,
		left: 0,
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: `100vh`,
		overflowY: 'auto',
		padding: theme.spacing(2),
		[`${theme.breakpoints.up('md')}`]: {
			padding: theme.spacing(4),
		},
		[`${theme.breakpoints.up('lg')}`]: {
			transform: 'translateX(0)',
			alignItems: 'center',
			width: theme.variables?.navWidthCollapsed,
			height: '100vh',
			padding: theme.spacing(0, 1),
		},
	},
	drawerExpanded: {
		[`${theme.breakpoints.up('lg')}`]: {
			boxShadow: theme.shadows[16],
			alignItems: 'flex-start',
			width: theme.variables?.navWidthExpanded,
		},
	},
	drawerOpen: {
		transform: 'translateX(0)',
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		flex: 1,
		gap: theme.spacing(1),
		margin: theme.spacing(3, 0, 0, 0),
		width: '100%',
		[`${theme.breakpoints.up('lg')}`]: {
			padding: theme.spacing(0, 0, 1, 0),
		},
	},
	logomark: {
		width: 40,
		height: 'auto',
		color: theme.palette.common.white,
		[`${theme.breakpoints.up('lg')}`]: {
			display: 'inline-flex',
			width: '80%',
		},
	},
	logo: {
		width: 140,
	},
	logoWrapper: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1),
		textDecoration: 'none',
		color: theme.palette.common.white,
		padding: theme.spacing(1, 0, 0, 1),
		[`${theme.breakpoints.up('lg')}`]: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: 70,
			padding: 0,
		},
	},
	logoWrapperExpanded: {
		justifyContent: 'flex-start',
		padding: theme.spacing(0, 1),
	},
	nav: {
		position: 'relative',
	},
	overlay: {
		transition: 'all 200ms',
		backgroundColor: 'rgba(0,0,0,0.2)',
		backdropFilter: 'blur(3px)',
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		opacity: 0,
		pointerEvents: 'none',
	},
	overlayExpanded: {
		opacity: 1,
		pointerEvents: 'all',
	},
	sublist: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1),
	},
}));

export default useStyles;
