// react
import { MouseEvent, useState } from 'react';

// material
import { Box, Grid, GridProps, SxProps } from '@mui/material';

// types
import { Order } from '../../../@types';

// ui
import { TableHeader, TableHeaderProps } from './TableHeader/TableHeader';

// props
export interface SortParams {
	e?: MouseEvent<HTMLButtonElement>;
	order: Order;
	value: TableHeaderProps['value'];
}

export type FnSort = ({ order, value }: SortParams) => void;

export interface TableHeadersProps {
	borderRadius?: number | string;
	color?: 'blue' | 'grey';
	hasBorder?: boolean;
	hasShadow?: boolean;
	headers: TableHeaderProps[];
	onSort?: FnSort;
	size?: 'sm' | 'md';
	spacing?: GridProps['spacing'];
}

export const TableHeaders = ({
	borderRadius = 2,
	color,
	hasBorder = false,
	hasShadow = true,
	headers,
	onSort,
	size = 'md',
	spacing = 2,
}: TableHeadersProps) => {
	// state
	const [activeSort, setActiveSort] = useState<TableHeaderProps['value'] | null>(null);

	return (
		<Box
			sx={(theme) => {
				let paddingV = 2;

				if (size === 'sm') {
					paddingV = 1.5;
				}

				let sx: SxProps = {
					backgroundColor: theme.palette.brand.blue[100],
					borderRadius,
					padding: theme.spacing(paddingV, 2),
				};

				if (color === 'grey') {
					sx = {
						...sx,
						backgroundColor: theme.palette.brand.grey[100],
					};
				}

				if (hasBorder) {
					sx = {
						...sx,
						borderColor: theme.palette.brand.blue[300],
						borderStyle: 'solid',
						borderWidth: 1,
					};
				}

				if (hasShadow) {
					sx = {
						...sx,
						boxShadow: theme.shadows[1],
					};
				}

				return sx;
			}}>
			<Grid alignItems="center" container spacing={spacing}>
				{headers.map((header, i) => {
					return (
						<TableHeader
							activeSort={activeSort}
							color={color}
							key={`header-${header.value}`}
							{...header}
							onSort={onSort}
							setActiveSort={setActiveSort}
						/>
					);
				})}
			</Grid>
		</Box>
	);
};
