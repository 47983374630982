// react
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';

// types
import { Order } from '../../../../@types';

// ui
import { FnSort, TableHeadersProps } from '../TableHeaders';

// material
import { Grid, GridProps, Typography } from '@mui/material';

// icons
import {
	ArrowDownwardOutlined as IconArrowDownward,
	ArrowUpwardOutlined as IconArrowUpward,
} from '@mui/icons-material';

// styles
import useStyles from './TableHeader.styles';

// props
export interface TableHeaderProps {
	activeSort?: string | null;
	color?: TableHeadersProps['color'];
	GridProps?: GridProps;
	label: string;
	onSort?: FnSort;
	setActiveSort?: Dispatch<SetStateAction<string | null>>;
	sort?: boolean;
	value: string;
	xs?: GridProps['xs'];
	sm?: GridProps['sm'];
	md?: GridProps['md'];
	lg?: GridProps['lg'];
	xl?: GridProps['xl'];
}

export const TableHeader = ({
	activeSort,
	color,
	GridProps,
	label,
	onSort,
	setActiveSort,
	sort,
	value,
	xs,
	sm,
	md,
	lg,
	xl,
}: TableHeaderProps) => {
	// hooks
	const styles = useStyles();

	// state
	const [order, setOrder] = useState<Order>('ASC');

	// vars
	const classes = [styles.btn];

	const handleSort = (e: MouseEvent<HTMLButtonElement>, value: TableHeaderProps['value']) => {
		if (onSort) {
			// define default order
			let orderNew = order;

			// if header is already sorted
			if (activeSort === value) {
				// alternate order
				orderNew = order === 'ASC' ? 'DESC' : 'ASC';
			}

			// run callback
			onSort({ e, order: orderNew, value });

			// set active sorted header
			if (setActiveSort) {
				setActiveSort(value);
			}

			// set the current order
			setOrder(orderNew);
		}
	};

	// if header is actively sorted
	if (activeSort === value) {
		// show sort icons on hover
		classes.push(styles.activeSort);
	}

	let labelColor = 'brand.blue.700';

	if (color === 'grey') {
		labelColor = 'brand.grey.800';
	}

	const header = (
		<Grid alignItems="center" container spacing={1}>
			<Grid item>
				<Typography color={labelColor} fontWeight={600} variant="caption">
					{label}
				</Typography>
			</Grid>
			{sort && (
				<Grid className={styles.icon} item>
					{order === 'ASC' ? <IconArrowUpward /> : <IconArrowDownward />}
				</Grid>
			)}
		</Grid>
	);

	const headerButton = (
		<button className={classes.join(' ')} onClick={(e) => handleSort(e, value)}>
			{header}
		</button>
	);

	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} {...GridProps}>
			{sort ? headerButton : header}
		</Grid>
	);
};
