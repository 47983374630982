// react
import { useContext } from 'react';

// context
import { ContextEntityUsers } from '../Entity.context';

// mui
import { Container } from '@mui/material';

// tables
import { TableUsers } from '../../../tables/cards/TableUsers/TableUsers';

export const Users = () => {
	// context
	const { isLoadingUsers, paginationUsers, refetchUsers, users } = useContext(ContextEntityUsers);

	return (
		<section>
			<Container maxWidth={false}>
				<TableUsers
					frame="ENTITY"
					isLoading={isLoadingUsers}
					pagination={paginationUsers}
					refetchUsers={refetchUsers}
					users={users}
				/>
			</Container>
		</section>
	);
};
