// react
import { MouseEvent, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Box, Button, Grid, IconButton, Popover, Typography } from '@mui/material';

// icons
import {
	ClearOutlined as IconClear,
	FilterAltOutlined as IconFilterAlt,
} from '@mui/icons-material';

// ui
import { FilterProps, Filters } from '../Filters/Filters';

// styles
import { theme } from '../../../theme';

export interface BtnFiltersProps {
	filters: FilterProps[];
	hasClose?: boolean;
	onReset?: () => void;
}

export const BtnFilters = ({ filters, hasClose = true, onReset }: BtnFiltersProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [anchorMenu, setAnchorMenu] = useState<HTMLButtonElement | null>(null);
	const [searchParams, setSearchParams] = useSearchParams();

	// handlers
	const handleResetFilters = () => {
		// clear all search params
		searchParams.forEach((value, key) => {
			searchParams.delete(key);
		});

		// clear search input
		const elQ = document.querySelector('#form-search-q') as HTMLInputElement;

		if (elQ) {
			elQ.value = '';
		}

		if (onReset) {
			onReset();
		}

		setSearchParams(searchParams);
	};

	const handleClose = () => {
		setAnchorMenu(null);
	};

	return (
		<>
			<Button
				aria-label={t('toggleFilters')}
				color="primary"
				disabled={!filters.length}
				fullWidth
				onClick={(e: MouseEvent<HTMLButtonElement>) => setAnchorMenu(e.currentTarget)}
				variant="outlined">
				<IconFilterAlt />
			</Button>
			<Popover
				anchorEl={anchorMenu}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				disablePortal={true}
				onClose={handleClose}
				open={Boolean(anchorMenu)}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}>
				<Box sx={{ position: 'relative', padding: theme.spacing(2), width: '350px' }}>
					<Grid container spacing={theme.spacing(2)}>
						<Grid item xs={12}>
							<Typography variant="h3">{t('filterBy')}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Filters filters={filters} />
						</Grid>
						<Grid item xs={12}>
							<Grid alignItems="center" container justifyContent="flex-end" spacing={2}>
								<Grid item>
									<Button onClick={handleClose} variant="text">
										{t('cancel')}
									</Button>
								</Grid>
								<Grid item>
									<Button onClick={handleResetFilters} variant="outlined">
										{t('reset')}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				{hasClose && (
					<IconButton
						aria-label={t('close')}
						onClick={handleClose}
						size="small"
						sx={(theme) => {
							return {
								position: 'absolute',
								top: theme.spacing(1),
								right: theme.spacing(1),
							};
						}}>
						<IconClear />
					</IconButton>
				)}
			</Popover>
		</>
	);
};
