// react
import { useState } from 'react';

// router
import { Link as RouterLink } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { Project, Loan } from '../../../@types/index';

// material
import { Box, Button, Grid, Link, Paper, Typography } from '@mui/material';

// ui
import { Amount } from '../../ui/Amount/Amount';
import { BtnExpand } from '../../ui/BtnExpand/BtnExpand';
import { Flag } from '../../ui/Flag/Flag';
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { Table, TableHeader } from '../../ui/Table/Table';
import { Time } from '../../ui/Time/Time';

// props
export interface CardProjectProps {
	isOnboarded?: boolean;
	isMock?: boolean;
	project: Project;
}

export const CardProject = ({ isOnboarded = true, isMock, project }: CardProjectProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isExpanded, setIsExpanded] = useState(false);

	const headers: TableHeader[] = [
		{
			label: t('loan'),
			value: 'idWbg',
			render: (idWbg: string) => {
				return (
					<Typography component="span" fontWeight={600}>
						{idWbg}
					</Typography>
				);
			},
		},
		{
			label: t('currency'),
			value: 'currency',
		},
		{
			align: 'right',
			label: t('amount'),
			value: 'amount',
			render: (amount: number, header: TableHeader, item: Loan) => {
				return <Amount currency={item.currency} value={amount} />;
			},
		},
		{
			label: t('closing'),
			value: 'dateClosing',
			render: (dateClosing: Date) => {
				return <Time date={new Date(dateClosing)} />;
			},
		},
	];

	const handleToggleCard = () => {
		setIsExpanded(!isExpanded);
	};

	let urlProject = `/project/${project.idWbg}?isWbg=true`;

	if (isMock) {
		urlProject += `&isMock=true`;
	}

	return (
		<Paper>
			<Box
				sx={(theme) => {
					return {
						position: 'relative',
						padding: theme.spacing(2),
						[theme.breakpoints.up('md')]: {
							padding: theme.spacing(3),
						},
						[theme.breakpoints.up('lg')]: {
							padding: theme.spacing(2, 2, 2, 3),
						},
					};
				}}>
				<Grid alignItems="center" container spacing={2}>
					<Grid item xs={12} lg={3}>
						<Grid alignItems="center" container spacing={2} wrap="nowrap">
							<Grid item>
								<IconCircle type="project" />
							</Grid>
							<Grid item>
								<Box component="span" sx={{ display: 'inline-flex' }}>
									{isOnboarded ? (
										<Link
											component={RouterLink}
											fontWeight={600}
											sx={(theme) => {
												return {
													[theme.breakpoints.up('lg')]: {
														maxWidth: 200,
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														whiteSpace: 'nowrap',
													},
												};
											}}
											to={`/project/${project.idWbg}`}
											underline="hover">
											{project.name}
										</Link>
									) : (
										<Typography
											fontWeight={600}
											sx={(theme) => {
												return {
													[theme.breakpoints.up('lg')]: {
														maxWidth: 200,
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														whiteSpace: 'nowrap',
													},
												};
											}}>
											{project.name}
										</Typography>
									)}
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} lg={1}>
						<Typography color="brand.purple.500" fontWeight={600}>
							{project.idWbg}
						</Typography>
					</Grid>
					<Grid item xs={12} lg={2}>
						<Grid alignItems="center" container spacing={1}>
							<Grid item>
								<Flag country={project.country.code} />
							</Grid>
							<Grid item>
								<Typography>{project.country.name}</Typography>
							</Grid>
						</Grid>
					</Grid>
					{project.loans?.length > 0 && (
						<Grid item xs={12} lg={2}>
							<Grid container spacing={{ xs: 1, lg: 0 }}>
								{project.loans.map((loan) => {
									return (
										<Grid item key={`loan-${loan.idWbg}`} xs="auto" lg={12}>
											<Typography color="brand.grey.500" fontWeight={600} variant="caption">
												{loan.idWbg}
											</Typography>
										</Grid>
									);
								})}
							</Grid>
						</Grid>
					)}

					<Grid item xs={12} lg={true}>
						<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={2}>
							{!isOnboarded && (
								<Grid
									item
									sx={(theme) => {
										return {
											position: 'absolute',
											top: 0,
											right: theme.spacing(2),
											[theme.breakpoints.up('lg')]: {
												position: 'relative',
												top: 'auto',
												right: 'auto',
											},
										};
									}}>
									<Button component={RouterLink} to={urlProject} variant="outlined">
										{t('onboard')}
									</Button>
								</Grid>
							)}

							<Grid item>
								<BtnExpand
									isExpanded={isExpanded}
									label={isExpanded ? t('hideLoans') : t('seeLoans')}
									onClick={handleToggleCard}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
			{isExpanded && (
				<div>
					<Table data={project.loans} headers={headers} />
				</div>
			)}
		</Paper>
	);
};
