// types
import { Period } from '../@types';

// i18n
import i18n from 'i18next';

interface TimeUnits {
	year: number;
	month: number;
	day: number;
	hour: number;
	minute: number;
	second: number;
}

type TimeUnit = keyof TimeUnits;

export const toRelativeTime = (d1: Date, lang = i18n.language, d2 = new Date()) => {
	const units: TimeUnits = {
		year: 24 * 60 * 60 * 1000 * 365,
		month: (24 * 60 * 60 * 1000 * 365) / 12,
		day: 24 * 60 * 60 * 1000,
		hour: 60 * 60 * 1000,
		minute: 60 * 1000,
		second: 1000,
	};

	const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' });

	const elapsed = d1.getTime() - d2.getTime();

	for (const u in units) {
		const ms = units[u as TimeUnit];
		if (Math.abs(elapsed) > ms || u === 'second') {
			return rtf.format(Math.round(elapsed / ms), u as Intl.RelativeTimeFormatUnit);
		}
	}
};

export interface FormatDateParams {
	date: Date;
	format?: 'YYYY-MM-DD';
	lang?: string;
}

export const formatDate = ({ date, format, lang = i18n.language }: FormatDateParams) => {
	if (!date) {
		return '';
	}

	if (format === 'YYYY-MM-DD') {
		return date.toLocaleDateString('en-CA', {
			timeZone: 'UTC',
		});
	}

	return date.toLocaleDateString(lang, {
		day: 'numeric',
		month: 'short',
		timeZone: 'UTC',
		year: 'numeric',
	});
};

export const getDatesByPeriod = (period: Period) => {
	// define current date
	const dateToday = new Date();

	// get current year
	const YYYY = dateToday.getFullYear();

	// get current month
	const MM = new Intl.DateTimeFormat('en-US', {
		month: '2-digit',
	}).format(dateToday);

	// get current day
	const DD = new Intl.DateTimeFormat('en-US', {
		day: '2-digit',
	}).format(dateToday);

	// date before will always be current date in this format
	const dateBefore = `${YYYY}-${MM}-${DD}`;
	let dateAfter = '';

	if (period === 'YEAR') {
		// define date after as first date of current year (Jan 1)
		dateAfter = `${YYYY}-01-01`;
	}

	if (period === 'MONTH') {
		// define date as first date of current month
		dateAfter = `${YYYY}-${MM}-01`;
	}

	if (period === 'QUARTER') {
		const monthNum = Number(MM);

		// set to the first month and day of the quarter
		if (monthNum <= 3) {
			// first quarter
			dateAfter = `${YYYY}-01-01`;
		} else if (monthNum >= 4 && monthNum <= 6) {
			// second quarter
			dateAfter = `${YYYY}-04-01`;
		} else if (monthNum >= 7 && monthNum <= 9) {
			// third quarter
			dateAfter = `${YYYY}-07-01`;
		} else {
			// fourth quarter
			dateAfter = `${YYYY}-10-01`;
		}
	}

	return {
		dateAfter,
		dateBefore,
	};
};
