// react
import { MouseEvent, useState } from 'react';

// types
import { ReportType } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Grid } from '@mui/material';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// forms
import { FormDownloadReport } from '../../forms/FormDownloadReport/FormDownloadReport';

// utils
import { getReports } from '../../../utils/reports';

// props
export interface DialogDownloadReportProps {
	isOpen: boolean;
	onClose: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

export const DialogDownloadReport = ({ isOpen, onClose }: DialogDownloadReportProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isLoading, setIsLoading] = useState(false);
	const [searchParams] = useSearchParams();

	// params
	const paramDialogReport = searchParams.get('dialogReport');

	// vars
	const type = paramDialogReport ? (paramDialogReport as ReportType) : null;
	const report = type ? getReports({ t, type })[0] : null;

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: onClose,
					variant: 'text',
				},
				{
					children: t('downloadReport'),
					form: 'form-download-report',
					loading: isLoading,
					type: 'submit',
					variant: 'contained',
				},
			]}
			alignItems="center"
			IconCircleProps={report?.IconCircleProps}
			isOpen={isOpen}
			maxWidth={400}
			onClose={onClose}
			subtitle={{
				children: report?.title,
			}}
			title={{
				children: t('downloadReport'),
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FormDownloadReport onClose={onClose} report={report} setIsLoading={setIsLoading} />
				</Grid>
			</Grid>
		</DialogActions>
	);
};
