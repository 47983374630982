// react
import { useContext } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

// context
import { ContextProject } from '../Project.context';

// mui
import { ChipProps, Container, Grid, Typography } from '@mui/material';

// cards
import { CardAction, CardActionProps } from '../../../cards/CardAction/CardAction';

// dialogs
import { DialogDownloadReport } from '../../../dialogs/DialogDownloadReport/DialogDownloadReport';

// utils
import { getReports } from '../../../../utils/reports';

export const Reports = () => {
	// hooks
	const { t } = useTranslation();

	// context
	const { project } = useContext(ContextProject);

	// state
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramDialog = searchParams.get('dialog');

	const PaperProps: CardActionProps['PaperProps'] = {
		sx: {
			height: '100%',
			minHeight: 300,
		},
	};

	const ButtonProps: CardActionProps['ButtonProps'] = {
		sx: (theme) => {
			return {
				border: 'none',
				height: '100%',
				padding: theme.spacing(2),
				color: 'black',
				'&:hover': {
					border: 'none',
				},
			};
		},
	};

	const title: CardActionProps['title'] = {
		variant: 'h2',
	};

	const reports = getReports({ t });

	const cards: CardActionProps[] = [];

	// for each report
	for (let i = 0; i < reports.length; i++) {
		const report = reports[i];

		const action = {
			children: t('downloadReport'),
		};

		let reportChipProps: ChipProps | null = null;

		const reportButtonProps: CardActionProps['ButtonProps'] = {
			...ButtonProps,
			disabled: report.disabled,
		};

		// soe
		if (report.type === 'SOE') {
			action.children = t('createReport');
			reportButtonProps.component = RouterLink;
			reportButtonProps.to = `/project/${project?.idWbg}/reports/soe`;
		}

		// other reports
		if (report.fileName) {
			reportButtonProps.onClick = () => {
				searchParams.set('dialog', 'downloadReport');
				searchParams.set('dialogReport', report.type);
				setSearchParams(searchParams);
			};
		}

		if (report.disabled) {
			reportChipProps = {
				color: 'error',
				label: t('comingSoon'),
				size: 'small',
				variant: 'outlined',
			};
		}

		cards.push({
			action,
			ButtonProps: reportButtonProps,
			ChipProps: reportChipProps,
			content: {
				children: report.content,
			},
			hasBreakLine: false,
			IconCircleProps: report.IconCircleProps,
			PaperProps,
			title: {
				...title,
				children: report.title,
			},
		});
	}

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Grid item xs={12}>
						<Container maxWidth={false}>
							<Typography variant="h2">{t('reports')}</Typography>
						</Container>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Container maxWidth={false}>
						<Grid container spacing={2}>
							{cards.map((card, i) => {
								return (
									<Grid item key={`report-${i}`} xs={12} md={6}>
										<CardAction {...card} />
									</Grid>
								);
							})}
						</Grid>
					</Container>
				</Grid>
			</Grid>
			<DialogDownloadReport
				isOpen={paramDialog === 'downloadReport'}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogReport');
					setSearchParams(searchParams);
				}}
			/>
		</>
	);
};
