// react
import { ChangeEvent, MouseEvent, ReactNode, useEffect, useState } from 'react';

// mui
import { Box, Grid, InputLabel, Typography } from '@mui/material';

// ui
import { IconCircle } from '../../ui/IconCircle/IconCircle';

// i18n
import { useTranslation } from 'react-i18next';

// fields
import { Input, InputProps } from '../Input/Input';

// theme
import { theme } from '../../../theme';

// utils
import { constructAmountInputs } from '../Amounts/Amounts.utils';
import { formatCurrency } from '../../../utils/currency';

// types
import { Account, AccountSource, Loan } from '../../../@types';

// styling
const sxStyles = {
	boxContainer: { border: `1px ${theme.palette.divider} solid`, borderRadius: '5px' },
	boxRow: {
		padding: theme.spacing(2),
		borderBottom: `1px ${theme.palette.divider} solid`,
		minWidth: '100%',
	},
};

// props
export interface AmountsTransferProps {
	formId: string;
	fromAccount?: Account;
	loans?: Loan[];
	sources?: AccountSource[];
	title: string;
	titleTotal?: string;
	toAccount?: Account;
}
export type AmountsTransferInputProps = {
	icon: ReactNode | null;
} & InputProps;

export const AmountsTransfer = ({
	formId,
	fromAccount,
	loans,
	sources,
	title,
	titleTotal,
	toAccount,
}: AmountsTransferProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [fromAccountTotal, setFromAccountTotal] = useState<number>(0);
	const [toAccountTotal, setToAccountTotal] = useState<number>(0);

	// vars
	let amountsInputs: AmountsTransferInputProps[] = [];

	if (loans && sources) {
		amountsInputs = constructAmountInputs({ sources, loans, t });
	}

	// handlers
	const handleChangeFromAccountSource = (
		e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		const totalBalance = amountsInputs.reduce(
			(total: number, input: AmountsTransferInputProps, i: number) =>
				total + Number(entries[`source-sending-${i}`]),
			0
		);

		setFromAccountTotal(totalBalance);
	};
	const handleChangeToAccountSource = (e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		const totalBalance = amountsInputs.reduce(
			(total: number, input: AmountsTransferInputProps, i: number) =>
				total + Number(entries[`source-receiving-${i}`]),
			0
		);

		setToAccountTotal(totalBalance);
	};

	// clear inputs when sources change
	useEffect(() => {
		if (sources) {
			for (let i = 0; i < sources.length; i++) {
				// get amount inputs
				const inputSending = document.getElementsByName(
					`source-sending-${i}`
				)[0] as HTMLInputElement;
				const inputReceiving = document.getElementsByName(
					`source-receiving-${i}`
				)[0] as HTMLInputElement;

				// reset amount
				inputSending.value = '';
				inputReceiving.value = '';
			}

			setFromAccountTotal(0);
			setToAccountTotal(0);
		}
	}, [sources]);

	if (!amountsInputs.length) {
		return <></>;
	}

	return (
		<>
			<InputLabel required={true}>{title}</InputLabel>

			<Box sx={sxStyles.boxContainer}>
				<Box sx={sxStyles.boxRow}>
					<Grid container alignItems={'center'} spacing={theme.spacing(2)}>
						<Grid item xs={4}>
							<Typography fontWeight={600}>{t('source')}</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography fontWeight={600}>{t('sending')}</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography fontWeight={600}>{t('receiving')}</Typography>
						</Grid>
					</Grid>
				</Box>

				<Grid container>
					{amountsInputs.map((input: AmountsTransferInputProps, i: number) => {
						return (
							<Box key={`source=${i}`} sx={sxStyles.boxRow}>
								<Grid alignItems="center" container spacing={2}>
									<Grid item xs={4}>
										<Grid alignItems="center" container spacing={2}>
											<Grid item xs="auto">
												<IconCircle icon={input.icon} type={input.type} />
											</Grid>
											<Grid item xs="auto">
												<InputLabel
													disabled={input.disabled}
													htmlFor={`${formId}-source-sending-${i}`}
													sx={{ margin: 0 }}>
													{input.label}
												</InputLabel>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={4}>
										<Input
											currency={fromAccount?.currency}
											disabled={input.disabled}
											id={`${formId}-source-sending-${i}`}
											name={`source-sending-${i}`}
											onWheel={(e: MouseEvent) => {
												const target = e.target as HTMLElement;
												return target.blur();
											}}
											onChange={handleChangeFromAccountSource}
											type="number"
											value={input.value}
										/>
									</Grid>
									<Grid item xs={4}>
										<Input
											currency={toAccount?.currency}
											disabled={input.disabled}
											id={`${formId}-source-receiving-${i}`}
											name={`source-receiving-${i}`}
											onWheel={(e: MouseEvent) => {
												const target = e.target as HTMLElement;
												return target.blur();
											}}
											onChange={handleChangeToAccountSource}
											type="number"
											value={input.value}
										/>
									</Grid>
								</Grid>
							</Box>
						);
					})}
					<Box
						sx={{
							...sxStyles.boxRow,
							border: 'none',
						}}>
						<Grid container alignItems={'center'} spacing={theme.spacing(2)}>
							<Grid item xs={4}>
								<Typography fontWeight={600}>{titleTotal || t('totalAmount')}</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography fontWeight={600}>
									{formatCurrency({
										currency: fromAccount?.currency?.toString(),
										value: fromAccountTotal,
									})}
								</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography fontWeight={600} color={theme.palette.brand.grey[500]}>
									{formatCurrency({
										currency: toAccount?.currency?.toString(),
										value: toAccountTotal,
									})}
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Box>
		</>
	);
};
