// react
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';

// types
import { ActionButton, Pagination, Project } from '../../../../@types';

// i18n
import { t } from 'i18next';

// router
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

// context
import { ContextUser } from '../../../../App.context';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// mui
import { Grid } from '@mui/material';

// ui
import { SortParams, TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardProject } from '../../../cards/CardProject/CardProject';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// icons
import { FolderOutlined as IconFolder } from '@mui/icons-material';

// defaults
import { defaultPagination } from '../../../../utils/defaults';

// props
import { EmptyProps } from '../../../ui/Empty/Empty';

export const TableProjects = () => {
	// params
	const [searchParams] = useSearchParams();
	const paramPage = Number(searchParams.get('page') || 1);
	const paramQ = searchParams.get('q');

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// context
	const { user } = useContext(ContextUser);

	// state
	const [pagination, setPagination] = useState<Pagination>({
		...defaultPagination,
		page: paramPage,
	});
	const [projects, setProjects] = useState<Project[]>([]);

	// fetch projects
	let urlProjects = `${process.env.REACT_APP_API_URL}/projects?page=${paramPage}&limit=10&populate=country,loans`;

	if (paramQ) {
		urlProjects += `&q=${paramQ}`;
	}

	const { fetchRequest: fetchProjects, isLoading: isLoadingProjects } = useFetch({
		isLazy: true,
		url: urlProjects,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setProjects(res.data);
			}

			if (res.pagination) {
				setPagination(res.pagination);
			}
		},
	});

	// headers
	const headers = [
		{
			label: t('name'),
			lg: 3,
			value: 'name',
		},
		{
			label: t('id'),
			lg: 1,
			value: 'idWbg',
		},
		{
			label: t('country'),
			lg: 2,
			value: 'country',
		},
		{
			label: t('financing'),
			lg: 1.5,
			value: 'financing',
		},
	];

	// memo
	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [],
			content: { children: t('empty.projects.content') },
			IconCircleProps: { icon: (<IconFolder />) as ReactNode },
			title: { children: t('empty.projects.title') },
		};

		if (paramQ) {
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		} else if (user?.role === 'WFA' && emptyProps.actions) {
			emptyProps.actions.push({
				children: t('onboardProject'),
				component: RouterLink,
				to: '/projects/onboard',
				variant: 'contained' as ActionButton['variant'],
			});
		}

		return emptyProps;
	}, [paramQ, user]);

	const content = useMemo(() => {
		if (isLoadingProjects || !projects.length) {
			return <CardEmpty empty={empty} isLoading={isLoadingProjects} />;
		}

		return (
			<Grid container spacing={2}>
				{projects.map((project, i) => {
					return (
						<Grid item key={`beneficiary-${i}`} xs={12}>
							<CardProject project={project} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, isLoadingProjects, projects]);

	// handlers
	const handleSort = ({ order, value }: SortParams) => {
		console.log(value, order);
	};

	// fetch projects when params change
	useEffect(() => {
		fetchProjects();
	}, [fetchProjects, searchParams]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormSearch />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} onSort={handleSort} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={projects.length} pagination={pagination} />
			</Grid>
		</Grid>
	);
};
