// react
import { ChangeEvent, useState } from 'react';

// mui
import {
	Alert,
	AppBar,
	Avatar,
	Badge,
	Button,
	ButtonGroup,
	ButtonProps,
	Checkbox,
	Chip,
	ChipProps,
	Container,
	Divider,
	FormControlLabel,
	FormLabel,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	Link,
	MenuItem,
	Pagination,
	PaginationItem,
	Paper,
	Radio,
	RadioGroup,
	Switch,
	Tab,
	TablePagination,
	Tabs,
	Toolbar,
	Typography,
	TextField,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

// ui
import { IconCircle } from '../../ui/IconCircle/IconCircle';

// theme
import { theme } from '../../../theme';

// img
import { LogoWorldBank } from '../../img/LogoWorldBank';
import { LogoWorldBankMark } from '../../img/LogoWorldBankMark';

// icons
import {
	AccountCircleOutlined as IconAccountCircle,
	ArrowBack as IconArrowBack,
	ArrowForward as IconArrowForward,
	ArrowUpward as IconArrowUpward,
	Clear as IconClear,
	DeleteOutlined as IconDelete,
	FolderOutlined as IconFolder,
	NotificationsOutlined as IconNotifications,
	PersonOutlined as IconPerson,
} from '@mui/icons-material';

// styles
import useStyles from './Theme.styles';

export const PageTheme = () => {
	// hooks
	const styles = useStyles();

	// state
	const [tabValue, setTabValue] = useState('overview');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	// vars
	const colors = ['blue', 'green', 'grey', 'magenta', 'orange', 'purple', 'teal', 'yellow'];
	const shades = [100, 200, 300, 400, 500, 600, 700, 800];
	const elevations = [
		0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
	];

	const icons = [
		{
			icon: <IconAccountCircle />,
			label: 'Account Circle',
		},
		{
			icon: <IconClear />,
			label: 'Clear',
		},
		{
			icon: <IconDelete />,
			label: 'Delete',
		},
		{
			icon: <IconFolder />,
			label: 'Folder',
		},
		{
			icon: <IconNotifications />,
			label: 'Notifications',
		},
		{
			icon: <IconPerson />,
			label: 'Person',
		},
	];

	const btns = [
		{
			color: 'primary',
			label: 'Primary',
		},
		{
			color: 'secondary',
			label: 'Secondary',
		},
		{
			color: 'neutral',
			label: 'Neutral',
		},
		{
			color: 'error',
			label: 'Error',
		},
		{
			color: 'info',
			label: 'Info',
		},
		{
			color: 'success',
			label: 'Success',
		},
		{
			color: 'warning',
			label: 'Warning',
		},
		{
			color: 'magenta',
			label: 'Magenta',
		},
		{
			color: 'purple',
			label: 'Purple',
		},
		{
			color: 'teal',
			label: 'Teal',
		},
		{
			color: 'primary',
			label: 'Disabled',
		},
	];

	const chips = [
		{
			color: 'default',
			label: 'Default',
		},
		{
			color: 'primary',
			label: 'Primary',
		},
		{
			color: 'secondary',
			label: 'Secondary',
		},
		{
			color: 'neutral',
			label: 'Neutral',
		},
		{
			color: 'error',
			label: 'Error',
		},
		{
			color: 'info',
			label: 'Info',
		},
		{
			color: 'success',
			label: 'Success',
		},
		{
			color: 'warning',
			label: 'Warning',
		},
		{
			color: 'magenta',
			label: 'Magenta',
		},
		{
			color: 'purple',
			label: 'Purple',
		},
		{
			color: 'teal',
			label: 'Teal',
		},
	];

	const iconCircleGroups = [
		{
			name: 'Misc',
			items: [
				{
					label: 'Project',
					type: 'project',
				},
				{
					label: 'Loan',
					type: 'loan',
				},
				{
					label: 'Document',
					type: 'document',
				},
				{
					label: 'Contract',
					type: 'contract',
				},
				{
					label: 'User',
					type: 'user',
				},
			],
		},
		{
			name: 'Accounts',
			items: [
				{
					label: 'Designated Account',
					type: 'account',
				},
				{
					label: 'Local Account',
					type: 'local',
				},
			],
		},
		{
			name: 'Entities',
			items: [
				{
					label: 'Entity',
					type: 'entity',
				},
				{
					label: 'Supplier',
					type: 'supplier',
				},
				{
					label: 'Organization (Beneficiary)',
					type: 'organization',
				},
				{
					label: 'Individual (Beneficiary)',
					type: 'individual',
				},
			],
		},
		{
			name: 'Transactions',
			items: [
				{
					label: 'Payment',
					type: 'payment',
				},
				{
					label: 'Operating Expense',
					type: 'opex',
				},
				{
					label: 'Transfer',
					type: 'transfer',
				},
				{
					label: 'Replenishment',
					type: 'replenishment',
				},
			],
		},
		{
			name: 'Default',
			items: [
				{
					label: 'Upload',
					type: 'upload',
				},
				{
					label: 'Search',
					type: 'search',
				},
			],
		},
	];

	const handleChangeRows = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setPage(0);
		setRowsPerPage(Number(e.target.value));
	};

	return (
		<main className={styles.theme}>
			<Container>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Logo
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Logo
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<LogoWorldBank className={styles.logo} />
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Logomark
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<LogoWorldBankMark className={styles.logoMark} />
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Typography
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h1">Heading One</Typography>
								<Typography variant="h2">Heading Two</Typography>
								<Typography variant="h3">Heading Three</Typography>
								<Typography variant="h4">Heading Four</Typography>
								<Typography variant="h5">Heading Five</Typography>
								<Typography variant="h6">Heading Six</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body1">Body 1</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body2">Body 2</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="subtitle1">Subtitle 1</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="subtitle2">Subtitle 2</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="button">Button</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="overline">Overline</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="caption">Caption</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Colors
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<Typography className={styles.subtitle} component="h3" variant="h4">
													Brand
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<div
															className={styles.swatch}
															style={{
																backgroundColor: theme?.palette?.primary?.main,
															}}></div>
														<Typography>Primary</Typography>
													</Grid>
													<Grid item xs={6}>
														<div
															className={styles.swatch}
															style={{
																backgroundColor: theme?.palette?.secondary?.main,
															}}></div>
														<Typography>Secondary</Typography>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>

									{colors.map((color) => {
										return (
											<Grid item key={`color-${color}`} xs={12}>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<Typography className={styles.subtitle} component="h3" variant="h4">
															{color}
														</Typography>
													</Grid>
													<Grid item xs={12}>
														<Grid container spacing={2}>
															{shades.map((shade) => {
																return (
																	<Grid item key={`${color}-${shade}`} xs={4} lg={true}>
																		<div
																			className={styles.swatch}
																			style={{
																				// @ts-ignore
																				backgroundColor: theme.palette.brand[color][shade],
																			}}></div>
																		<Typography>{shade}</Typography>
																	</Grid>
																);
															})}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										);
									})}
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Icons
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography>
									The icons used are Google Material UI icons and use the <strong>outlined</strong>{' '}
									style.
								</Typography>
								<Typography>
									All icons can be exported from the <strong>@mui/icons-material</strong> package
									but here is a sample of icons:
								</Typography>
								<br />
							</Grid>
							{icons.map((icon, i) => {
								return (
									<Grid item key={`icon-${i}`} xs="auto">
										{icon.icon}
										<Typography component="div" variant="caption">
											{icon.label}
										</Typography>
									</Grid>
								);
							})}
							<Grid item xs={12}>
								See all icons{' '}
								<Link
									href="https://mui.com/material-ui/material-icons/?theme=Outlined"
									target="_blank">
									here
								</Link>
								.
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Icon Circle
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography>
									The IconCircle component is used to identify different resources.
								</Typography>
								<Typography>
									It is used in various contexts, such as table rows, dialogs, lists and quick
									action buttons.
								</Typography>
								<br />
							</Grid>
							<Grid item xs={12}>
								<Grid container columnSpacing={2} rowSpacing={{ xs: 2, md: 4 }}>
									{iconCircleGroups.map((group) => {
										return (
											<Grid item xs={12} md={3} lg={true}>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<Typography variant="h4">{group.name}</Typography>
													</Grid>
													<Grid item xs={12}>
														<Grid container spacing={2}>
															{group.items.map((item) => {
																return (
																	<Grid item xs={12}>
																		<Grid alignItems="center" container spacing={1}>
																			<Grid item>
																				<IconCircle type={item.type} />
																			</Grid>
																			<Grid item flex={1}>
																				<Typography fontWeight={600}>{item.label}</Typography>
																			</Grid>
																		</Grid>
																	</Grid>
																);
															})}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										);
									})}
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Buttons
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Contained
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											{btns.map((btn, i) => {
												return (
													<Grid item key={`btn-contained-${i}`} xs={12} md={4} lg={3} xl={2}>
														<Button
															color={btn.color as ButtonProps['color']}
															disabled={btn.label === 'Disabled'}
															fullWidth={true}
															variant="contained">
															{btn.label}
														</Button>
													</Grid>
												);
											})}
										</Grid>
									</Grid>

									<Grid item xs={12}>
										<Typography component="h3" variant="h4">
											Outlined
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											{btns.map((btn, i) => {
												return (
													<Grid item key={`btn-outlined-${i}`} xs={12} md={4} lg={3} xl={2}>
														<Button
															color={btn.color as ButtonProps['color']}
															disabled={btn.label === 'Disabled'}
															fullWidth={true}
															variant="outlined">
															{btn.label}
														</Button>
													</Grid>
												);
											})}
										</Grid>
									</Grid>

									<Grid item xs={12}>
										<Typography component="h3" variant="h4">
											Text
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											{btns.map((btn, i) => {
												return (
													<Grid item key={`btn-text-${i}`} xs={12} md={4} lg={3} xl={2}>
														<Button
															color={btn.color as ButtonProps['color']}
															disabled={btn.label === 'Disabled'}
															fullWidth={true}
															variant="text">
															{btn.label}
														</Button>
													</Grid>
												);
											})}
										</Grid>
									</Grid>

									<Grid item xs={12}>
										<Typography component="h3" variant="h4">
											White
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<div className={styles.bgDark}>
											<Grid item xs={12} md={4} lg={3} xl={2}>
												<Button color="white" fullWidth={true} variant="text">
													White
												</Button>
											</Grid>
										</div>
									</Grid>
									<Grid item xs={12}>
										<Typography component="h3" variant="h4">
											Icon Button
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs="auto">
												<IconButton size="small">
													<IconClear />
												</IconButton>
											</Grid>
											<Grid item xs="auto">
												<IconButton size="medium">
													<IconClear />
												</IconButton>
											</Grid>
											<Grid item xs="auto">
												<IconButton size="large">
													<IconClear />
												</IconButton>
											</Grid>
											<Grid item xs="auto">
												<IconButton color="primary" size="small">
													<IconClear />
												</IconButton>
											</Grid>
											<Grid item xs="auto">
												<IconButton color="primary" size="medium">
													<IconClear />
												</IconButton>
											</Grid>
											<Grid item xs="auto">
												<IconButton color="primary" size="large">
													<IconClear />
												</IconButton>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<div className={styles.bgDark}>
											<Grid container spacing={2}>
												<Grid item xs="auto">
													<IconButton color="white" size="small">
														<IconNotifications />
													</IconButton>
												</Grid>
												<Grid item xs="auto">
													<IconButton color="white" size="medium">
														<IconNotifications />
													</IconButton>
												</Grid>
												<Grid item xs="auto">
													<IconButton color="white" size="large">
														<IconNotifications />
													</IconButton>
												</Grid>
											</Grid>
										</div>
									</Grid>
									<Grid item xs={12}>
										<Typography component="h3" variant="h4">
											Button Group
										</Typography>
									</Grid>
									<Grid item xs="auto">
										<ButtonGroup disableElevation={true} variant="contained">
											<Button>One</Button>
											<Button>Two</Button>
											<Button>Three</Button>
										</ButtonGroup>
									</Grid>
									<Grid item xs="auto">
										<ButtonGroup variant="outlined">
											<Button>One</Button>
											<Button>Two</Button>
											<Button>Three</Button>
										</ButtonGroup>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Text Field
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>

							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Outlined
								</Typography>
							</Grid>

							<Grid item xs={12} md={4}>
								<InputLabel htmlFor="example-input-outlined">Label</InputLabel>
								<TextField
									fullWidth={true}
									helperText="Some example helper text"
									id="example-input-outlined"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<IconPerson />
											</InputAdornment>
										),
									}}
									variant="outlined"
								/>
							</Grid>

							<Grid item xs={12} md={4}>
								<InputLabel htmlFor="example-input-outlined-small">Label</InputLabel>
								<TextField
									error={true}
									fullWidth={true}
									helperText="Some example error text"
									id="example-input-outlined-small"
									size="small"
									type="date"
									variant="outlined"
								/>
							</Grid>

							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Filled
								</Typography>
							</Grid>

							<Grid item xs={12} md={4}>
								<InputLabel htmlFor="example-input-filled">Label</InputLabel>
								<TextField
									fullWidth={true}
									id="example-input-filled"
									InputProps={{
										disableUnderline: true,
										hiddenLabel: true,
									}}
									variant="filled"
								/>
							</Grid>

							<Grid item xs={12} md={4}>
								<InputLabel htmlFor="example-input-filled-small">Label</InputLabel>
								<TextField
									fullWidth={true}
									id="example-input-filled-small"
									InputProps={{
										disableUnderline: true,
										hiddenLabel: true,
										startAdornment: (
											<InputAdornment position="start">
												<IconPerson />
											</InputAdornment>
										),
									}}
									size="small"
									variant="filled"
								/>
							</Grid>

							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									White
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<div className={styles.bgDark}>
									<Grid container spacing={2}>
										<Grid item xs={12} md={4}>
											<InputLabel htmlFor="example-input-filled-small-white">Label</InputLabel>
											<TextField
												color="white"
												fullWidth={true}
												id="example-input-filled-small-white"
												InputProps={{
													disableUnderline: true,
													hiddenLabel: true,
													startAdornment: (
														<InputAdornment position="start">
															<IconPerson />
														</InputAdornment>
													),
												}}
												size="small"
												variant="filled"
											/>
										</Grid>
									</Grid>
								</div>
							</Grid>

							<Grid item xs={12}>
								<Alert severity="info">
									The color="white" prop only works with variant="filled".
								</Alert>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Select
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>

							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Outlined
								</Typography>
							</Grid>

							<Grid item xs={12} md={4}>
								<InputLabel htmlFor="example-select-outlined">Label</InputLabel>
								<TextField defaultValue={30} fullWidth id="example-select-outlined" select={true}>
									<MenuItem value={10}>Ten</MenuItem>
									<MenuItem value={20}>Twenty</MenuItem>
									<MenuItem value={30}>Thirty</MenuItem>
								</TextField>
							</Grid>

							<Grid item xs={12} md={4}>
								<InputLabel htmlFor="example-select-outlined-small">Label</InputLabel>
								<TextField
									defaultValue={30}
									fullWidth
									id="example-select-outlined-small"
									size="small"
									select={true}>
									<MenuItem value={10}>Ten</MenuItem>
									<MenuItem value={20}>Twenty</MenuItem>
									<MenuItem value={30}>Thirty</MenuItem>
								</TextField>
							</Grid>

							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Filled
								</Typography>
							</Grid>

							<Grid item xs={12} md={4}>
								<InputLabel htmlFor="example-select-filled">Label</InputLabel>
								<TextField
									defaultValue={30}
									fullWidth
									id="example-select-filled"
									InputProps={{
										disableUnderline: true,
										hiddenLabel: true,
									}}
									select={true}
									variant="filled">
									<MenuItem value={10}>Ten</MenuItem>
									<MenuItem value={20}>Twenty</MenuItem>
									<MenuItem value={30}>Thirty</MenuItem>
								</TextField>
							</Grid>

							<Grid item xs={12} md={4}>
								<InputLabel htmlFor="example-select-filled-small">Label</InputLabel>
								<TextField
									defaultValue={30}
									fullWidth
									id="example-select-filled-small"
									InputProps={{
										disableUnderline: true,
										hiddenLabel: true,
									}}
									select={true}
									size="small"
									variant="filled">
									<MenuItem value={10}>Ten</MenuItem>
									<MenuItem value={20}>Twenty</MenuItem>
									<MenuItem value={30}>Thirty</MenuItem>
								</TextField>
							</Grid>

							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									White
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<div className={styles.bgDark}>
									<Grid container spacing={2}>
										<Grid item xs={12} md={4}>
											<InputLabel htmlFor="example-select-filled-small">Label</InputLabel>
											<TextField
												color="white"
												defaultValue={30}
												fullWidth
												id="example-select-filled-small"
												InputProps={{
													disableUnderline: true,
													hiddenLabel: true,
												}}
												select={true}
												size="small"
												variant="filled">
												<MenuItem value={10}>Ten</MenuItem>
												<MenuItem value={20}>Twenty</MenuItem>
												<MenuItem value={30}>Thirty</MenuItem>
											</TextField>
										</Grid>
									</Grid>
								</div>
							</Grid>

							<Grid item xs={12}>
								<Alert severity="info">
									The color="white" prop only works with variant="filled".
								</Alert>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Checkbox
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={12}>
												<FormControlLabel
													control={<Checkbox defaultChecked={true} />}
													label="Label"
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlLabel
													control={<Checkbox defaultChecked={false} />}
													label="Label"
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlLabel
													control={<Checkbox disabled />}
													label="Disabled Unchecked"
												/>
											</Grid>
											<Grid item xs={12}>
												<FormControlLabel
													control={<Checkbox defaultChecked={true} disabled />}
													label="Disabled Checked"
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Radio
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<RadioGroup
											aria-labelledby="example-radio-group-label"
											name="example-radio-group">
											<Grid container>
												<Grid item xs={12}>
													<FormLabel id="example-radio-group-label">Label</FormLabel>
												</Grid>
												<Grid item xs={12}>
													<FormControlLabel control={<Radio />} label="Ten" value={10} />
												</Grid>
												<Grid item xs={12}>
													<FormControlLabel control={<Radio />} label="Twenty" value={20} />
												</Grid>
												<Grid item xs={12}>
													<FormControlLabel
														control={<Radio disabled />}
														label="Thirty"
														value={30}
													/>
												</Grid>
											</Grid>
										</RadioGroup>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Chip
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Filled
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									{chips.map((chip, i) => {
										return (
											<Grid item key={`chip-filled-${i}`} xs={6} md="auto">
												<Chip color={chip.color as ChipProps['color']} label={chip.label} />
											</Grid>
										);
									})}
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Outlined
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									{chips.map((chip, i) => {
										return (
											<Grid item key={`chip-outlined-${i}`} xs={6} md="auto">
												<Chip
													color={chip.color as ChipProps['color']}
													label={chip.label}
													variant="outlined"
												/>
											</Grid>
										);
									})}
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Two Tone
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									{chips.map((chip, i) => {
										return (
											<Grid item key={`chip-twoTone-${i}`} xs={6} md="auto">
												<Chip
													color={chip.color as ChipProps['color']}
													label={chip.label}
													variant="twoTone"
												/>
											</Grid>
										);
									})}
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Tag
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									{chips.map((chip, i) => {
										return (
											<Grid item key={`chip-tag-${i}`} xs={6} md="auto">
												<Chip
													className="tag"
													color={chip.color as ChipProps['color']}
													label={chip.label}
													size="small"
													variant="twoTone"
												/>
											</Grid>
										);
									})}
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Circle
								</Typography>
							</Grid>
							{chips.map((chip, i) => {
								return (
									<Grid item key={`chip-circle-${i}`} xs={6} md="auto">
										<Chip
											className="circle"
											color={chip.color as ChipProps['color']}
											icon={<IconArrowUpward />}
										/>
									</Grid>
								);
							})}
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Alert
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Standard
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="error">An example of an error alert</Alert>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="info">An example of an info alert</Alert>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="success">An example of a success alert</Alert>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="warning">An example of a warning alert</Alert>
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Filled
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="error" variant="filled">
									An example of an error alert
								</Alert>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="info" variant="filled">
									An example of an info alert
								</Alert>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="success" variant="filled">
									An example of a success alert
								</Alert>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="warning" variant="filled">
									An example of a warning alert
								</Alert>
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Outlined
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="error" variant="outlined">
									An example of an error alert
								</Alert>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="info" variant="outlined">
									An example of an info alert
								</Alert>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="success" variant="outlined">
									An example of a success alert
								</Alert>
							</Grid>
							<Grid item xs={12}>
								<Alert severity="warning" variant="outlined">
									An example of a warning alert
								</Alert>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Tabs
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<TabContext value={tabValue}>
									<Tabs onChange={(e, value) => setTabValue(value)} value={tabValue}>
										<Tab label="Overview" value="overview" />
										<Tab iconPosition="top" label="Transactions" value="transactions" />
										<Tab label="Reports" value="reports" />
									</Tabs>
									<br />
									<TabPanel value="overview">
										<Typography>
											Nec dubitamus multa iter quae et nos invenerat. Qui ipsorum lingua Celtae,
											nostra Galli appellantur. Quid securi etiam tamquam eu fugiat nulla pariatur.
										</Typography>
									</TabPanel>
									<TabPanel value="transactions">
										<Typography>
											Ullamco laboris nisi ut aliquid ex ea commodi consequat. Cras mattis iudicium
											purus sit amet fermentum. Hi omnes lingua, institutis, legibus inter se
											differunt.
										</Typography>
									</TabPanel>
									<TabPanel value="reports">
										<Typography>
											Idque Caesaris facere voluntate liceret: sese habere. Curabitur est gravida et
											libero vitae dictum. Ullamco laboris nisi ut aliquid ex ea commodi consequat.
										</Typography>
									</TabPanel>
								</TabContext>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Avatar
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs="auto">
								<Avatar className="small">
									<IconPerson />
								</Avatar>
							</Grid>
							<Grid item xs="auto">
								<Avatar>
									<IconPerson />
								</Avatar>
							</Grid>
							<Grid item xs="auto">
								<Badge color="error" badgeContent={3} overlap="circular">
									<Avatar className="large">
										<IconPerson />
									</Avatar>
								</Badge>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									App Bar
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<AppBar color="secondary" position="relative">
									<Toolbar>
										<Typography>App Bar</Typography>
									</Toolbar>
								</AppBar>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Paper
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							{elevations.map((elevation, i) => {
								return (
									<Grid item key={`elevation-${i}`} xs={12} md={3} lg={2}>
										<Paper elevation={elevation} style={{ padding: 16 }}>
											Elevation {elevation}
										</Paper>
									</Grid>
								);
							})}
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Pagination
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Circular
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Pagination color="primary" count={10} onChange={(e, page) => console.log(page)} />
							</Grid>
							<Grid item xs={12}>
								<Pagination
									color="primary"
									count={10}
									onChange={(e, page) => console.log(page)}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Rounded
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Pagination
									color="primary"
									count={10}
									onChange={(e, page) => console.log(page)}
									renderItem={(item) => (
										<PaginationItem
											slots={{
												previous: IconArrowBack,
												next: IconArrowForward,
											}}
											{...item}
										/>
									)}
									shape="rounded"
								/>
							</Grid>
							<Grid item xs={12}>
								<Pagination
									color="primary"
									count={10}
									onChange={(e, page) => console.log(page)}
									shape="rounded"
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Table
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TablePagination
									component="div"
									count={100}
									page={page}
									onPageChange={(e, page) => setPage(page)}
									rowsPerPage={rowsPerPage}
									onRowsPerPageChange={handleChangeRows}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography component="h2" variant="h3">
									Switch
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									Default
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Switch
									color="primary"
									inputProps={{
										'aria-label': 'Example Switch',
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography component="h3" variant="h4">
									With Label
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel control={<Switch color="primary" />} label="Label" />
							</Grid>
							<Grid item xs={12}>
								<FormControlLabel
									control={<Switch color="primary" />}
									disabled={true}
									label="Disabled"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</main>
	);
};
