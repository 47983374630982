// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// types
import { User } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';

// props
export interface FormEditUserProps {
	onClose?: () => void;
	refetchUsers?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	user: User;
}

export const FormEditUser = ({ onClose, refetchUsers, setIsLoading, user }: FormEditUserProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [error, setError] = useState<string | null>(null);

	// vars
	const idForm = `form-edit-user`;

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		const body = {
			...entries,
		};

		try {
			// set loader
			setIsLoading(true);

			const fetchEditUser = await fetch(`${process.env.REACT_APP_API_URL}/users/${user.id}`, {
				method: 'PATCH',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
				body: JSON.stringify(body),
			});
			const resEditUser = await fetchEditUser.json();

			if (resEditUser.error) {
				throw new Error(resEditUser.error.message, {
					cause: {
						id: resEditUser.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			// refetch
			if (refetchUsers) {
				refetchUsers();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.userEdited', { name: `${entries.firstName} ${entries.lastName}` }),
				severity: 'success',
			});
		} catch (error) {
			const message = t('error.default');

			// set error
			setError(message);
		} finally {
			// set loader
			setIsLoading(false);
		}
	};

	return (
		<form id={idForm} name="formEditUser" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={user.firstName}
						id={`${idForm}-user-first-name`}
						label={t('firstName')}
						name="firstName"
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={user.lastName}
						id={`${idForm}-user-last-name`}
						label={t('lastName')}
						name="lastName"
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={user.email}
						disabled={true}
						id={`${idForm}-user-email`}
						label={t('email')}
						name="email"
						required={true}
						type="email"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={user.phone}
						disabled={true}
						id={`${idForm}-user-phone`}
						label={t('phone')}
						name="phone"
						required={true}
						type="tel"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={user.title}
						id={`${idForm}-user-title`}
						label={t('jobTitle')}
						name="title"
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
