// react
import { SyntheticEvent } from 'react';

// types
import { FilterType } from '../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Grid, GridProps, SelectChangeEvent } from '@mui/material';

// ui
import { Autocomplete, AutocompleteProps } from '../../fields/Autocomplete/Autocomplete';

// fields
import { Select, SelectProps } from '../../fields/Select/Select';
import { Input, InputProps } from '../../fields/Input/Input';

// props
export type FilterProps = {
	GridProps?: GridProps;
	onChange?: (
		e: SelectChangeEvent<unknown> | SyntheticEvent<Element, Event>,
		filter?: FilterProps
	) => void;
	type?: FilterType;
} & AutocompleteProps &
	SelectProps &
	InputProps;

export interface FiltersProps {
	filters: FilterProps[];
}

export const Filters = ({ filters }: FiltersProps) => {
	// state
	const [searchParams] = useSearchParams();

	// handlers
	const handleChangeFilter = (
		e: SelectChangeEvent<unknown> | SyntheticEvent<Element, Event>,
		filter: FilterProps
	) => {
		// remove search param
		searchParams.delete('q');

		// clear search input
		const elQ = document.querySelector('#form-search-q') as HTMLInputElement;

		if (elQ) {
			elQ.value = '';
		}

		if (filter.onChange) {
			filter.onChange(e, filter);
		}
	};

	return (
		<Grid container spacing={2}>
			{filters.map((filter, i) => {
				const { GridProps, type, ...propsFilter } = filter;
				const name = String(filter.name);
				const key = `filter-${i}-${name}`;

				if (type === 'INPUT') {
					return (
						<Grid item key={key} xs={12} {...GridProps}>
							<Input {...propsFilter} onChange={(e) => handleChangeFilter(e, filter)} />
						</Grid>
					);
				} else if (type === 'AUTOCOMPLETE') {
					return (
						<Grid item key={key} xs={12} {...GridProps}>
							<Autocomplete
								{...propsFilter}
								disablePortal={true}
								onChange={(e) => handleChangeFilter(e, filter)}
							/>
						</Grid>
					);
				} else {
					const { multiple } = filter as SelectProps;

					return (
						<Grid item key={key} xs={12} {...GridProps}>
							<Select
								{...propsFilter}
								MenuProps={{
									disablePortal: true,
								}}
								multiple={multiple}
								onChange={(e) => handleChangeFilter(e, filter)}
							/>
						</Grid>
					);
				}
			})}
		</Grid>
	);
};
