// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// context
import { ContextProject, ContextTransfers } from '../../pages/Project/Project.context';

// types
import {
	Account,
	ErrorCause,
	Loan,
	PaymentPayload,
	TransactionTransferMetadata,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid, SelectChangeEvent } from '@mui/material';

// fields
import { AmountsTransfer } from '../../fields/AmountsTransfer/AmountsTransfer';
import { FilePicker } from '../../fields/FilePicker/FilePicker';
import { Input } from '../../fields/Input/Input';
import { Select } from '../../fields/Select/Select';

// utils
import { formatDate } from '../../../utils/dates';

// props
export interface FormAddTransferProps {
	onClose?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormAddTransfer = ({ onClose, setIsLoading }: FormAddTransferProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [files, setFiles] = useState<File[]>([]);
	const [fromAccount, setFromAccount] = useState<string | null>('');
	const [toAccount, setToAccount] = useState<string | null>('');

	// context
	const { project } = useContext(ContextProject);
	const { setBanner } = useContext(ContextBanner);
	const { refetchTransfers } = useContext(ContextTransfers);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// vars
	const fromAccountSelected = accounts.find((a) => a.id === Number(fromAccount));
	const fromAccountLoans: Loan[] = fromAccountSelected
		? fromAccountSelected.sources.filter((s) => s.loan).map((s) => s.loan as Loan)
		: [];
	const toAccountSelected = accounts.find((a) => a.id === Number(toAccount));
	const formId = 'form-record-transfer';

	// check currencies
	let isCurrencyMismatch = false;

	if (fromAccountSelected && toAccountSelected) {
		isCurrencyMismatch = fromAccountSelected.currency !== toAccountSelected.currency;
	}

	// check if from account has disabled loans
	let hasDisabledLoans = false;

	for (let i = 0; i < fromAccountLoans.length; i++) {
		if (fromAccountLoans[i]?.status === 'DISABLED') {
			hasDisabledLoans = true;
			break;
		}
	}

	// fetch accounts
	useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/accounts?project=${project?.id}&fields=id,institution,name,currency,sources,status&populate=sources.loan`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setAccounts(res.data);
		},
	});

	// options
	const optionsAccounts = accounts.map((account) => {
		return {
			disabled: account.status === 'DEACTIVATED',
			label: account.name,
			secondary: account.institution,
			value: String(account.id),
		};
	});

	// handlers
	const handleChangeFromAccount = async (e: SelectChangeEvent<unknown>) => {
		setFromAccount(String(e.target.value));
	};

	const handleChangeToAccount = async (e: SelectChangeEvent<unknown>) => {
		setToAccount(String(e.target.value));
	};

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		// define payments using sources
		const payments: PaymentPayload[] = [];

		let total = 0;
		let totalReceived = 0;

		if (fromAccountSelected) {
			for (let s = 0; s < fromAccountSelected.sources.length; s++) {
				const source = fromAccountSelected.sources[s];
				const amount = Number(entries[`source-sending-${s}`]);
				const amountReceived = Number(entries[`source-receiving-${s}`]) || null;

				total += amount;
				totalReceived += amountReceived || 0;

				if (amount > 0) {
					payments.push({
						amount,
						amountReceived,
						currency: fromAccountSelected.currency,
						currencyReceived: toAccountSelected?.currency,
						loan: source.loan ? (source.loan as Loan).id : null,
						source: source.source,
					});
				}
			}
		}

		const metadata: TransactionTransferMetadata = {
			comments: entries.comments as string,
			uuid: crypto.randomUUID(),
		};

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			if (fromAccountSelected && toAccountSelected) {
				if (fromAccountSelected.id === toAccountSelected.id) {
					throw new Error('The sending and receiving accounts must be different.', {
						cause: {
							id: 'TRANSACTION_ACCOUNTS_SAME',
						},
					});
				}
			}

			if (total === 0) {
				throw new Error('The payment amount needs to be greater than 0.', {
					cause: {
						id: 'TRANSACTION_AMOUNT_NON_EXISTENT',
					},
				});
			}

			if (isCurrencyMismatch && totalReceived === 0) {
				throw new Error('The received amount needs to be greater than 0.', {
					cause: {
						id: 'TRANSACTION_RECEIVED_AMOUNT_NON_EXISTENT',
					},
				});
			}

			const body = {
				date: formatDate({ date: new Date(entries.date as string), format: 'YYYY-MM-DD' }),
				fromAccount: Number(entries.fromAccount),
				metadata,
				payments,
				project: project?.id,
				status: 'COMPLETED',
				toAccount: Number(entries.toAccount),
				type: 'TRANSFER',
			};

			const fetchAddTransfer = await fetch(`${process.env.REACT_APP_API_URL}/transactions`, {
				method: 'post',
				body: JSON.stringify(body),
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});
			const resAddTransfer = await fetchAddTransfer.json();

			if (resAddTransfer.error) {
				throw new Error(resAddTransfer.error.message, {
					cause: {
						id: resAddTransfer.error.id,
					},
				});
			}

			const transaction = resAddTransfer.data;

			// for each file
			for (let i = 0; i < files.length; i++) {
				// define form data
				const formData = new FormData();

				const dateToday = new Date();
				const year = dateToday.getFullYear();
				const month = new Intl.DateTimeFormat('en-US', {
					month: '2-digit',
				}).format(dateToday);

				// append form data
				formData.append(
					'path',
					`bldt/projects/${project?.id}/transactions/transfers/${transaction.id}/${year}/${month}/`
				);
				formData.append('file', files[i]);
				formData.append('transaction', String(transaction.id));

				if (project) {
					formData.append('project', String(project.id));
				}

				// POST document
				const fetchPostDoc = await fetch(`${process.env.REACT_APP_API_URL}/documents`, {
					method: 'post',
					body: formData,
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				});

				const resPostDoc = await fetchPostDoc.json();

				if (resPostDoc.error) {
					throw new Error(resPostDoc.error.message, {
						cause: {
							id: resPostDoc.error.id,
						},
					});
				}
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			// refetch transfers
			refetchTransfers();

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.transferRecorded'),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'DOCUMENT_INVALID_MIME_TYPE') {
					message = t('error.documentInvalidMimeType');
				}

				if (cause.id === 'TRANSACTION_ACCOUNTS_SAME') {
					message = t('error.transactionAccountsSame');
				}

				if (cause.id === 'TRANSACTION_AMOUNT_NON_EXISTENT') {
					message = t('error.transactionAmountNonExistent');
				}

				if (cause.id === 'TRANSACTION_INSUFFICIENT_FUNDS') {
					message = t('error.transactionInsufficientFunds');
				}

				if (cause.id === 'TRANSACTION_RECEIVED_AMOUNT_NON_EXISTENT') {
					message = t('error.transactionReceivedAmountNonExistent');
				}

				if (cause.id === 'TRANSACTION_UNAUTHORIZED_POST') {
					message = t('error.unauthorized');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	return (
		<form id={formId} name="formAddTransfer" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Input
						id={`${formId}-date`}
						inputProps={{
							max: new Date().toISOString().split('T')[0],
						}}
						label={t('transferDate')}
						name="date"
						required={true}
						type="date"
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Select
						helperText={
							!(fromAccount && toAccount)
								? t('formAddTransaction.payment.sendingAccount.helper')
								: undefined
						}
						id={`${formId}-from-account`}
						label={t('sendingAccount')}
						name="fromAccount"
						options={optionsAccounts}
						required={true}
						onChange={handleChangeFromAccount}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Select
						id={`${formId}-to-account`}
						label={t('receivingAccount')}
						name="toAccount"
						options={optionsAccounts}
						required={true}
						onChange={handleChangeToAccount}
					/>
				</Grid>

				{isCurrencyMismatch && (
					<Grid item xs={12}>
						<Alert severity="info">{t('alert.currencyMismatch')}</Alert>
					</Grid>
				)}

				{fromAccount && toAccount && (
					<>
						<Grid item xs={12}>
							<AmountsTransfer
								formId={formId}
								loans={fromAccountLoans}
								fromAccount={fromAccountSelected}
								toAccount={toAccountSelected}
								sources={fromAccountSelected ? fromAccountSelected.sources : []}
								title={t('sendingAccountFinancingSources')}
							/>
						</Grid>
						{hasDisabledLoans && (
							<Grid item xs={12}>
								<Alert severity="info">{t('alert.financingSourcesDisabled')}</Alert>
							</Grid>
						)}
					</>
				)}

				<Grid item xs={12}>
					<FilePicker onChange={(e, filesSelected) => setFiles(filesSelected)} />
				</Grid>

				<Grid item xs={12}>
					<Input
						id={`${formId}-comments`}
						label={t('comments')}
						minRows={2}
						multiline={true}
						name="comments"
					/>
				</Grid>

				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
