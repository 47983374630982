// react
import { SyntheticEvent } from 'react';

// l18n
import { TFunction } from 'i18next';

// mui
import { Link, Typography, Checkbox as MuiCheckbox } from '@mui/material';

// table
import { TableHeader } from '../../../ui/Table/Table';

// types
import {
	Account,
	Contract,
	Entity,
	ExpenseType,
	Metadata,
	Project,
	Transaction,
	TransactionStatus,
	TransactionType,
	WithdrawalApplication,
} from '../../../../@types';

// ui
import { ChipStatus } from '../../../ui/ChipStatus/ChipStatus';
import { Time } from '../../../ui/Time/Time';

// links
import { LinkEntity } from '../../../links/LinkEntity/LinkEntity';
import { LinkAccount } from '../../../links/LinkAccount/LinkAccount';

// router
import { Link as RouterLink } from 'react-router-dom';

// utils
import { translateTransactionType } from '../../../../utils/translations';
import { formatCurrency } from '../../../../utils/currency';

// props
export interface DocumentHeaderTableProps {
	handleChangeCheckBox: (e: SyntheticEvent<Element>, id: number) => void;
	t: TFunction<'translation', undefined>;
	transaction?: Transaction;
	transactionsUnchecked: number[];
	project?: Project | null | undefined;
}

export const getTransactionsHeaders = ({
	t,
	project,
	handleChangeCheckBox,
	transactionsUnchecked,
}: DocumentHeaderTableProps): TableHeader[] => {
	const columns: TableHeader[] = [
		{
			label: t(''),
			value: '',
			render: (value: null, header: TableHeader, transaction: Transaction) => {
				return (
					<MuiCheckbox
						checked={!transactionsUnchecked.includes(transaction.id)}
						onChange={(e) => handleChangeCheckBox(e, transaction.id)}
					/>
				);
			},
		},
		{
			label: t('type'),
			value: 'type',
			render: (type: TransactionType) => {
				return <Typography fontWeight={600}>{translateTransactionType(type, t)}</Typography>;
			},
		},
		{
			label: t('date'),
			value: 'date',
			render: (date: Date) => {
				return <Time date={new Date(date)} />;
			},
		},
		{
			label: t('expenseType'),
			value: 'expenseType',
			render: (expenseType: ExpenseType) => {
				return <Typography>{expenseType ? expenseType.name : t('na')}</Typography>;
			},
		},
		{
			label: t('status'),
			value: 'status',
			render: (status: TransactionStatus) => {
				return <ChipStatus status={status} />;
			},
		},
		{
			label: t('amount'),
			value: 'amount',
			render: (amount: number, header: TableHeader, transaction: Transaction) => {
				return formatCurrency({ currency: transaction.currency, value: amount });
			},
		},
		{
			label: t('withdrawalApplication'),
			value: 'withdrawalApplication',
			render: (withdrawalApplication: WithdrawalApplication) => {
				return (
					<Typography>
						{withdrawalApplication ? withdrawalApplication.idExternal : t('na')}
					</Typography>
				);
			},
		},
		{
			label: t('fromEntity'),
			value: 'fromEntity',
			render: (fromEntity: Entity) => {
				return fromEntity ? <LinkEntity entity={fromEntity} /> : <Typography>{t('na')}</Typography>;
			},
		},
		{
			label: t('toEntity'),
			value: 'toEntity',
			render: (toEntity: Entity) => {
				return toEntity ? <LinkEntity entity={toEntity} /> : <Typography>{t('na')}</Typography>;
			},
		},
		{
			label: t('fromAccount'),
			value: 'fromAccount',
			render: (fromAccount: Account) => {
				return fromAccount ? (
					<LinkAccount account={fromAccount} />
				) : (
					<Typography>{t('na')}</Typography>
				);
			},
		},
		{
			label: t('toAccount'),
			value: 'toAccount',
			render: (toAccount: Account) => {
				return toAccount ? <LinkAccount account={toAccount} /> : <Typography>{t('na')}</Typography>;
			},
		},
		{
			label: t('contract'),
			value: 'contract',
			render: (contract: Contract) => {
				return contract ? (
					<Link
						component={RouterLink}
						fontWeight={600}
						to={`/project/${project?.idWbg}/contracts/`}
						underline="hover">
						{contract.num}
					</Link>
				) : (
					<Typography>{t('na')}</Typography>
				);
			},
		},
		{
			label: t('stepId'),
			value: 'stepId',
			render: (contract: Contract) => {
				return <Typography>{contract ? contract?.idStep : t('na')}</Typography>;
			},
		},
		{
			label: t('invoiceAmount'),
			value: 'metadata',
			render: (metadata: Metadata) => {
				return (
					<Typography>
						{metadata.invoiceAmount && metadata.invoiceCurrency
							? formatCurrency({
									currency: metadata.invoiceCurrency as string,
									value: Number(metadata.invoiceAmount),
							  })
							: t('na')}
					</Typography>
				);
			},
		},
		{
			label: t('invoiceCurrency'),
			value: 'metadata',
			render: (metadata: Metadata) => {
				return <Typography>{metadata.invoiceCurrency || t('na')}</Typography>;
			},
		},
		{
			label: t('created'),
			value: 'created',
			render: (created: Date) => {
				return <Time date={new Date(created)} />;
			},
		},
	];

	return columns;
};
