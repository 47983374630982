// react
import { useContext } from 'react';

// context
import { ContextSupplierContracts } from '../Supplier.context';

// mui
import { Container } from '@mui/material';

// tables
import { TableContracts } from '../../../tables/cards/TableContracts/TableContracts';

export const Contracts = () => {
	// context
	const { contracts, paginationContracts, refetchContracts } = useContext(ContextSupplierContracts);

	return (
		<section>
			<Container maxWidth={false}>
				<TableContracts
					contracts={contracts}
					frame="SUPPLIER"
					pagination={paginationContracts}
					refetchContracts={refetchContracts}
				/>
			</Container>
		</section>
	);
};
