// react
import { ReactNode, useContext, useMemo, useState } from 'react';

// types
import { ActionButton, Contract, Entity, Frame, Option, Pagination } from '../../../../@types';

// i18next
import { t } from 'i18next';

// router
import { useSearchParams } from 'react-router-dom';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// context
import { ContextProject } from '../../../pages/Project/Project.context';

// mui
import { Grid } from '@mui/material';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// cards
import { CardContract } from '../../../cards/CardContract/CardContract';
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';

// ui
import { BtnFilters } from '../../../ui/BtnFilters/BtnFilters';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';
import { SortParams, TableHeaders } from '../../../ui/TableHeaders/TableHeaders';

// icons
import { AssignmentOutlined as IconAssignment } from '@mui/icons-material';

// props
import { EmptyProps } from '../../../ui/Empty/Empty';

// props
export interface TableContractsProps {
	contracts: Contract[];
	frame?: Frame;
	isLoading?: boolean;
	pagination: Pagination;
	refetchContracts?: () => void;
}

export const TableContracts = ({
	contracts,
	frame = 'PROJECT',
	isLoading,
	pagination,
	refetchContracts,
}: TableContractsProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// context
	const { project } = useContext(ContextProject);

	// state
	const [searchParams, setSearchParams] = useSearchParams();
	const [suppliers, setSuppliers] = useState<Entity[]>([]);

	// params
	const paramQ = searchParams.get('q');

	// fetch suppliers
	useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&type=SUPPLIER&fields=id,name`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setSuppliers(res.data);
			}
		},
	});

	// vars
	const optionsSuppliers: Option[] = suppliers.map((supplier) => {
		return {
			label: supplier.name,
			value: String(supplier.id),
		};
	});

	optionsSuppliers.unshift({
		label: t('all'),
		value: 'all',
	});

	const formIdFilters = 'form-contracts-filters';
	const filters = [];

	if (frame === 'PROJECT') {
		filters.push({
			label: t('supplier'),
			defaultValue: 'all',
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formIdFilters}-supplier`,
			name: 'supplier',
			options: optionsSuppliers,
		});
	}

	const hasFilters = filters.length > 0;

	const headers = [
		{
			label: t('contract'),
			lg: 2,
			value: 'num',
		},
	];

	if (frame === 'PROJECT') {
		headers.push({
			label: t('supplier'),
			lg: 3,
			value: 'supplier',
		});
	}

	headers.push(
		{
			label: t('amount'),
			lg: 2,
			value: 'amount',
		},
		{
			label: t('stepId'),
			lg: 2,
			value: 'idStep',
		},
		{
			label: t('created'),
			lg: 2,
			value: 'created',
		}
	);

	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [
				{
					children: t('addContract'),
					onClick: () => {
						searchParams.set('dialog', 'addContract');
						setSearchParams(searchParams);
					},
					variant: 'contained' as ActionButton['variant'],
				},
			],
			content: { children: t('empty.contracts.content') },
			IconCircleProps: { icon: (<IconAssignment />) as ReactNode },
			title: { children: t('empty.contracts.title') },
		};

		if (paramQ) {
			emptyProps.actions = [];
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		}

		return emptyProps;
	}, [paramQ, searchParams, setSearchParams]);

	const content = useMemo(() => {
		if (isLoading || !contracts.length) {
			return <CardEmpty empty={empty} isLoading={isLoading} />;
		}

		return (
			<Grid container spacing={2}>
				{contracts.map((contract, i) => {
					return (
						<Grid item key={`loan-${i}`} xs={12}>
							<CardContract contract={contract} frame={frame} refetchContracts={refetchContracts} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, frame, isLoading, contracts, refetchContracts]);

	// handlers
	const handleSort = ({ order, value }: SortParams) => {
		console.log(value, order);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={hasFilters ? true : 12}>
				<FormSearch />
			</Grid>
			{hasFilters && (
				<Grid item xs="auto">
					<BtnFilters filters={filters} />
				</Grid>
			)}
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} onSort={handleSort} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={contracts.length} pagination={pagination} />
			</Grid>
		</Grid>
	);
};
