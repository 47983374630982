// mui
import { Tooltip, Typography, TypographyProps } from '@mui/material';

// utils
import { formatCurrency } from '../../../utils/currency';

// props
export interface AmountProps {
	currency?: string;
	notation?: Intl.NumberFormatOptions['notation'];
	TypographyProps?: TypographyProps;
	value: number;
}

export const Amount = ({
	currency,
	notation = 'standard',
	TypographyProps,
	value,
}: AmountProps) => {
	const amount = formatCurrency({ currency, notation, value: value });
	const amountCompact = formatCurrency({ currency, notation: 'compact', value: value });
	const amountStandard = formatCurrency({ currency, notation: 'standard', value: value });

	if (notation === 'compact') {
		return (
			<Tooltip placement="top" title={amountStandard}>
				<Typography component="div" {...TypographyProps}>
					{amountCompact}
				</Typography>
			</Tooltip>
		);
	}

	return (
		<Typography component="div" {...TypographyProps}>
			{amount}
		</Typography>
	);
};
