// i18n
import i18n from 'i18next';

interface FormatCurrencyParams {
	currency?: string;
	decimals?: number;
	notation?: Intl.NumberFormatOptions['notation'];
	lang?: string;
	value: number;
}

export const formatCurrency = ({
	currency = 'USD',
	decimals = 2,
	notation = 'standard',
	lang = i18n.language,
	value,
}: FormatCurrencyParams) => {
	if (notation === 'compact') {
		return new Intl.NumberFormat(lang, {
			maximumFractionDigits: decimals,
			notation: 'compact',
			compactDisplay: 'short',
			style: 'currency',
			currency: currency,
		}).format(value);
	}

	return new Intl.NumberFormat(lang, {
		maximumFractionDigits: decimals,
		notation,
		style: 'currency',
		currency: currency,
	}).format(value);
};
