// react
import { MouseEvent, useContext, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// types
import {
	Account,
	Contract,
	Entity,
	ErrorCause,
	ExpenseType,
	Loan,
	Transaction,
	TransactionType,
	WithdrawalApplication,
} from '../../../@types';

// context
import { ContextBanner, ContextUser } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// mui
import { Alert, Box, Grid, SxProps, Typography } from '@mui/material';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// fields
import { Input } from '../../fields/Input/Input';
import { Checkbox } from '../../fields/Checkbox/Checkbox';

// utils
import { downloadFile, jsonToCsv } from '../../../utils/files';
import { translateStatus, translateTransactionType } from '../../../utils/translations';
import { formatDate } from '../../../utils/dates';

// props
export interface DialogExportSoeReportProps {
	expenseTypes: ExpenseType[];
	filtersValues: Record<string, string | string[]>;
	loans: Loan[];
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: () => void;
}

export const DialogExportSoeReport = ({
	expenseTypes,
	filtersValues,
	isOpen,
	loans,
	onClose,
	onSuccess,
}: DialogExportSoeReportProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [error, setError] = useState<{ message: string; errors?: string[] } | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [searchParams] = useSearchParams();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// params
	const paramId = searchParams.get('id');

	// vars
	const idForm = 'form-export-seo-report';

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		// clear error
		setError(null);

		// set loading
		setIsLoading(true);

		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// const fetch transactions url
			const populate = ['expenseType', 'fromAccount', 'fromEntity', 'toAccount', 'toEntity'];

			if (entries.contractAndSupplierColumns === 'on') {
				populate.push('contract');
			}

			if (entries.waColumns === 'on') {
				populate.push('withdrawalApplication');
			}

			let urlFetchTransactions = `${
				process.env.REACT_APP_API_URL
			}/transactions?populate=${populate.join(',')}`;

			// define filters keys
			const filtersKeys = Object.keys(filtersValues);

			// add each filter value to url
			for (let i = 0; i < filtersKeys.length; i++) {
				const key = filtersKeys[i];

				if (Array.isArray(filtersValues[key])) {
					if (filtersValues[key].length) {
						urlFetchTransactions += `&${key}=${(filtersValues[key] as string[]).join(',')}`;
					}
				} else if (filtersValues[key]) {
					urlFetchTransactions += `&${key}=${filtersValues[key]}`;
				}
			}

			// add id param
			if (paramId) {
				urlFetchTransactions += `&id=${paramId}`;
			}

			// fetch transaction ids
			const fetchGetTransactionIds = await fetch(`${urlFetchTransactions}&fields=id`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});

			const resGetTransactionIds = await fetchGetTransactionIds.json();

			if (resGetTransactionIds.error) {
				throw new Error(resGetTransactionIds.error.message, {
					cause: {
						id: resGetTransactionIds.error.id,
					},
				});
			}

			if (entries['withdrawalApplication']) {
				const postWithdrawalApplication = await fetch(
					`${process.env.REACT_APP_API_URL}/withdrawal-applications/`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${accessToken}`,
							User: String(idToken),
						},
						body: JSON.stringify({
							idExternal: String(entries['withdrawalApplication']),
							project: project?.id,
							user: user?.id ? user?.id : undefined,
						}),
					}
				);

				const resPostWithdrawalApplication = await postWithdrawalApplication.json();

				if (resPostWithdrawalApplication.error) {
					throw new Error(resPostWithdrawalApplication.error.message, {
						cause: {
							id: resPostWithdrawalApplication.error.id,
						},
					});
				}

				// patch transactions with new WA id
				const reqUpdateTransaction = await fetch(`${process.env.REACT_APP_API_URL}/transactions`, {
					method: 'PATCH',
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
					body: JSON.stringify(
						resGetTransactionIds.data.map((tx: Transaction) => {
							return {
								id: tx.id,
								withdrawalApplication: resPostWithdrawalApplication.data.id,
							};
						})
					),
				});
				const resUpdateTransactions = await reqUpdateTransaction.json();

				if (resUpdateTransactions.error) {
					throw new Error(resUpdateTransactions.error.message, {
						cause: {
							id: resUpdateTransactions.error.id,
						},
					});
				}

				if (resUpdateTransactions.data.failed.length) {
					throw new Error(
						`${resUpdateTransactions.data.failed.length} transactions failed to update.`,
						{
							cause: {
								id: 'TRANSACTION_PATCH_INCOMPLETE',
								res: resUpdateTransactions,
							},
						}
					);
				}
			}

			// fetch full transactions
			const fetchGetTransactions = await fetch(urlFetchTransactions, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});

			const resGetTransactions = await fetchGetTransactions.json();

			if (resGetTransactions.error) {
				throw new Error(resGetTransactions.error.message, {
					cause: {
						id: resGetTransactions.error.id,
					},
				});
			}

			// format transactions for csv
			const dataCsv = resGetTransactions.data.map((transaction: Transaction) => {
				let data = {
					ID: transaction.id,
					[t('amount')]: transaction.amount,
					[t('date')]: transaction.date,
					[t('expenseType')]: (transaction.expenseType as ExpenseType)?.name,
					[t('status')]: translateStatus(transaction.status, t),
					[t('type')]: translateTransactionType(transaction.type, t),
					[t('fromEntity')]: (transaction.fromEntity as Entity)?.name,
					[t('toEntity')]: (transaction.toEntity as Entity)?.name,
					[t('fromAccount')]: (transaction.fromAccount as Account)?.name,
					[t('toAccount')]: (transaction.toAccount as Account)?.name,
					[t('created')]: transaction.created,
					[t('modified')]: transaction.modified,
				};

				if (entries.contractAndSupplierColumns === 'on') {
					data = {
						...data,
						[t('contract')]: (transaction.contract as Contract)?.num || '',
						[t('stepId')]: (transaction.contract as Contract)?.idStep || '',
						[t('invoiceAmount')]: transaction.metadata?.invoiceAmount || '',
						[t('invoiceCurrency')]: transaction.metadata?.invoiceCurrency || '',
					};
				}

				if (entries.waColumns === 'on') {
					data = {
						...data,
						[t('withdrawalApplication')]: (
							transaction.withdrawalApplication as WithdrawalApplication
						)?.idExternal,
					};
				}

				return data;
			});

			// download json as csv
			const csv = jsonToCsv({ array: dataCsv });
			const dateToday = formatDate({ date: new Date(), format: 'YYYY-MM-DD' });

			downloadFile({
				data: csv,
				name: `soe-${dateToday}.csv`,
				type: 'text/csv',
			});

			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.reportDownloaded'),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'WITHDRAWAL_APPLICATION_ID_DUPLICATE') {
					message = t('error.withdrawalApplicationIdDuplicate', {
						idExternal: entries['withdrawalApplication'],
					});
				}

				if (cause.id === 'TRANSACTION_PATCH_INCOMPLETE') {
					message = t('error.transactionsPatchIncomplete', {
						failed: cause.res.data.failed.length,
					});
				}
			}

			// set error
			setError({
				message,
			});
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	const filtersKeyValues: { label: string; value: string | string[] | undefined }[] = [];

	if (filtersValues.loan) {
		filtersKeyValues.push({
			label: t('financingSource'),
			value: loans.find((loan) => loan.id === Number(filtersValues.loan))?.idWbg,
		});
	}

	if (filtersValues.type.length) {
		filtersKeyValues.push({
			label: t('transactionType'),
			value: (filtersValues.type as string[])
				.map((type) => translateTransactionType(type as TransactionType, t))
				.join(', '),
		});
	}

	if (filtersValues.expenseType.length) {
		filtersKeyValues.push({
			label: t('expenseType'),
			value: (filtersValues.expenseType as string[])
				.map((exp) => expenseTypes.find((expenseType) => expenseType.id === Number(exp))?.name)
				.join(', '),
		});
	}

	if (filtersValues.dateStart) {
		filtersKeyValues.push({
			label: t('startDate'),
			value: filtersValues.dateStart,
		});
	}

	if (filtersValues.dateEnd) {
		filtersKeyValues.push({
			label: t('endDate'),
			value: filtersValues.dateEnd,
		});
	}

	if (filtersValues.amountMax) {
		filtersKeyValues.push({
			label: t('maxAmount'),
			value: filtersValues.amountMax,
		});
	}

	if (filtersValues.amountMin) {
		filtersKeyValues.push({
			label: t('minAmount'),
			value: filtersValues.amountMin,
		});
	}

	if (filtersValues.currency) {
		filtersKeyValues.push({
			label: t('currency'),
			value: filtersValues.currency,
		});
	}

	return (
		<DialogActions
			actions={[
				{
					children: t('downloadCsv'),
					form: idForm,
					loading: isLoading,
					type: 'submit',
					variant: 'contained',
				},
			]}
			hasClose={true}
			isOpen={isOpen}
			onClose={onClose}
			title={{
				children: t('exportReport'),
				variant: 'h3',
			}}>
			<Grid item xs={12}>
				<form id={idForm} name="formExportSoeReport" onSubmit={handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Box
								sx={(theme) => {
									return {
										backgroundColor: theme.palette.brand.grey[100],
										border: `1px ${theme.palette.divider} solid`,
										borderRadius: 2,
										overflow: 'hidden',
									};
								}}>
								{filtersKeyValues.map((filter, i: number) => {
									const isLast = i === filtersKeyValues.length - 1;

									return (
										<Box
											key={`filter-data-${i}`}
											sx={(theme) => {
												let sx: SxProps = {
													padding: theme.spacing(1, 2),
												};

												if (!isLast) {
													sx = {
														...sx,
														borderBottom: `1px ${theme.palette.divider} solid`,
													};
												}
												return sx;
											}}>
											<Grid
												alignItems="center"
												container
												justifyContent="space-between"
												spacing={2}>
												<Grid item>
													<Typography fontWeight={600}>{t(filter?.label)}</Typography>
												</Grid>
												<Grid item xs={6} sx={{ textAlign: 'right' }}>
													<Typography color="brand.grey.500">{filter?.value}</Typography>
												</Grid>
											</Grid>
										</Box>
									);
								})}
							</Box>
						</Grid>
						{user?.permissions.transaction.update && (
							<>
								<Grid item xs={12}>
									<Input
										id={`${idForm}-withdrawal-application`}
										label={t('withdrawalApplication')}
										name="withdrawalApplication"
									/>
								</Grid>
								<Grid item xs={12}>
									<Alert severity="info">{t('alert.existingWithdrawalApplications')}</Alert>
								</Grid>
							</>
						)}

						<Grid item xs={12}>
							<Typography variant="h3">{t('includedColumns')}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Checkbox
								defaultChecked={true}
								disableRipple={true}
								id={`${idForm}-transaction-columns`}
								label={t('transactionColumns')}
								name="transactionColumns"
								required={true}
							/>
						</Grid>
						<Grid item xs={12}>
							<Checkbox
								defaultChecked={true}
								disableRipple={true}
								id={`${idForm}-contract-and-supplier-columns`}
								label={t('contractAndSupplierColumns')}
								name="contractAndSupplierColumns"
							/>
						</Grid>
						<Grid item xs={12}>
							<Checkbox
								defaultChecked={true}
								disableRipple={true}
								id={`${idForm}-withdrawal-columns`}
								label={t('waColumns')}
								name="waColumns"
							/>
						</Grid>
						{error && (
							<Grid item xs={12}>
								<Alert severity="error">{error.message}</Alert>
							</Grid>
						)}
					</Grid>
				</form>
			</Grid>
		</DialogActions>
	);
};
