// react
import { MouseEvent, useContext, useState } from 'react';

// types
import { Account } from '../../../@types';

// i18n
import { Trans, useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid, Typography } from '@mui/material';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// props
export interface DialogUpdateAccountStatusProps {
	account: Account;
	isOpen: boolean;
	onClose: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
	refetchAccounts?: () => void;
}

export const DialogUpdateAccountStatus = ({
	account,
	isOpen,
	onClose,
	refetchAccounts,
}: DialogUpdateAccountStatusProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);

	// state
	const [error, setError] = useState<string | null>(null);

	// vars
	const isActivating = account?.status === 'DEACTIVATED';

	// deactivate translations
	const dialog = {
		alert: t('alert.deactivateAccount'),
		btnSubmitChildren: t('deactivateAccount'),
		i18nKey: 'dialogUpdateAccountStatus.deactivate.content',
		title: t('deactivateAccount'),
	};

	// activate translations
	if (isActivating) {
		dialog.btnSubmitChildren = t('activateAccount');
		dialog.alert = t('alert.activateAccount');
		dialog.i18nKey = 'dialogUpdateAccountStatus.activate.content';
		dialog.title = t('activateAccount');
	}

	// patch account
	const { fetchRequest: updateAccount, isLoading: isLoadingUpdateAccount } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/accounts/${account.id}`,
		options: {
			method: 'PATCH',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
			body: JSON.stringify({
				status: isActivating ? 'ACTIVE' : 'DEACTIVATED',
			}),
		},
		onError: (error) => {
			let msg = t('error.default');

			if (error.id === 'ACCOUNT_UNAUTHORIZED_PATCH') {
				msg = t('error.unauthorized');
			}

			setError(msg);
		},
		onSuccess: () => {
			// set banner
			setBanner({
				hasClose: true,
				message: isActivating
					? t('alert.accountActivated', { name: account.name })
					: t('alert.accountDeactivated', { name: account.name }),
				severity: 'success',
			});

			// refetch accounts
			if (refetchAccounts) {
				refetchAccounts();
			}

			// close dialog
			if (onClose) {
				onClose();
			}
		},
	});

	// handlers
	const handleSubmit = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear error
		setError(null);

		// update account wallet status
		updateAccount();
	};

	const handleClose = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear error
		setError(null);

		if (onClose) {
			onClose(e);
		}
	};

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: handleClose,
					variant: 'text',
				},
				{
					children: dialog.btnSubmitChildren,
					loading: isLoadingUpdateAccount,
					onClick: handleSubmit,
					variant: 'contained',
				},
			]}
			alignItems="center"
			IconCircleProps={{
				type: account.type === 'LOCAL' ? 'local' : 'account',
			}}
			isOpen={isOpen}
			maxWidth={360}
			onClose={handleClose}
			subtitle={{
				children: account.name,
			}}
			title={{
				children: dialog.title,
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Trans
						components={{
							strong: <Typography color="brand.blue.600" component="span" fontWeight={600} />,
						}}
						i18nKey={dialog.i18nKey}
						values={{ name: account.name }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="warning">{dialog.alert}</Alert>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</DialogActions>
	);
};
