export interface LogoFundsChainAltProps {
	[x: string]: unknown;
}

export const LogoFundsChainAlt = ({ ...props }: LogoFundsChainAltProps) => {
	return (
		<svg fill="none" viewBox="0 0 385 41" xmlns="http://www.w3.org/2000/svg" {...props}>
			<clipPath id="a">
				<path d="m0 0h385v41h-385z" />
			</clipPath>
			<g clipPath="url(#a)">
				<path
					d="m53.7253 40.7528c-4.3946 0-7.8462-1.2177-10.3731-3.6622s-3.7903-5.9235-3.7903-10.4371v-11.5542c0-4.5136 1.2634-7.99264 3.7903-10.43713 2.5269-2.4445 5.9877-3.66217 10.3731-3.66217h24.4175v9.0089h-22.3667c-.8331 0-1.6479.1465-2.4536.4395s-1.529.6958-2.179 1.2085c-.6409.5219-1.1627 1.1261-1.5564 1.8311-.3937.7049-.5951 1.474-.5951 2.298v10.19c0 .8331.1922 1.593.5951 2.298.3937.7049.9064 1.3184 1.5564 1.8311.6409.5218 1.3642.9247 2.179 1.2085.8057.2929 1.6296.4303 2.4536.4303h22.3667v9.0089z"
					fill="#1575b3"
				/>
				<path
					d="m35.7899 5.7242h-22.559c-4.24808 0-7.69052 3.44243-7.69052 7.6905v27.5853"
					stroke="#fff"
					strokeMiterlimit="10"
					strokeWidth="10.6739"
				/>
				<path d="m23.3202 16.3262h-9.7231v9.723h9.7231z" fill="#117243" />
				<path d="m35.8356 16.3262h-9.723v9.723h9.723z" fill="#2cb486" />
				<g clipRule="evenodd" fillRule="evenodd">
					<path
						d="m215.289 40.1065s-1.151.3795-3.175.3795c-3.492 0-5.352-1.9608-5.352-4.2125v-.0506c-.594 1.2524-2.517 4.3137-7.982 4.3137-5.085 0-10.55-4.0986-10.55-13.4471 0-9.3486 5.629-13.5484 11.36-13.5484 4.693 0 6.173 2.4541 6.338 2.7324v-15.73686h7.16v31.75206c0 1.6445.493 2.4541 2.188 2.4541v5.3511zm-9.349-15.8507c-.05-1.1512-1.417-4.4782-5.186-4.4782-3.062 0-5.465 2.353-5.465 7.2739s2.783 7.2739 5.515 7.2739c4.263 0 5.086-4.3138 5.136-4.6933v-5.351zm-106.7676 15.9519v-38.25422h24.1496v6.1227h-16.61v10.55032h13.055v6.0088h-13.055v15.5724zm53.8266-.1012s-1.088.3795-3.112.3795c-4.858 0-5.414-3.4914-5.414-4.3769v-.0506c-.987 2.0746-3.441 4.4781-8.577 4.4781-6.059 0-8.362-3.8203-8.362-9.5129v-17.1537h7.16v15.5724c0 3.4409 1.531 4.8071 3.719 4.8071 4.314 0 5.25-4.5288 5.25-5.6294v-14.7501h7.16v18.5199c0 1.4801.494 2.3529 2.189 2.3529v5.3511zm28.564.3795c2.024 0 3.175-.3795 3.175-.3795v-.0126-5.3511c-1.695 0-2.188-.8222-2.188-2.3529v-8.4124c0-5.7306-2.682-10.4364-9.349-10.4364-4.87 0-7.058 2.3023-7.817 4.5414v-.0506c0-2.568-1.974-4.4276-5.25-4.4276-2.024 0-3.112.3795-3.112.3795v5.3511c1.695 0 2.188.8728 2.188 2.3529v18.5199h7.11v-15.079c.05-2.2391 1.366-5.3005 4.756-5.3005 2.783 0 4.314 1.9102 4.314 4.8071v8.9058c0 4.7565 2.125 6.9449 6.173 6.9449zm53.776-18.7982c0 1.1512-.278 1.9102-.278 1.9102h6.287s.443-1.0373.443-2.5174c0-3.8204-2.568-7.5395-10.765-7.5395-8.198 0-11.145 4.0986-11.145 8.3618 0 5.9946 5.301 7.106 9.512 7.9887 2.773.5814 5.073 1.0636 5.073 2.7766 0 1.4295-1.151 2.3529-4.048 2.3529-5.085 0-7.868-3.9848-7.868-3.9848l-3.985 3.9848s2.505 5.5155 12.182 5.5155c7.768 0 11.423-3.2258 11.423-8.1973 0-5.4924-4.941-6.4021-9.179-7.1821-3.18-.5853-5.963-1.0976-5.963-3.4188 0-1.7457 1.417-2.783 4.099-2.783 3.061 0 4.212 1.1511 4.212 2.7324z"
						fill="#fff"
					/>
					<path
						d="m347.521 9.25264c-2.504 0-4.465-1.74573-4.465-4.25048 0-2.50474 1.961-4.351655 4.465-4.351655 2.505 0 4.517 1.846915 4.517 4.351655 0 2.50475-2.012 4.25048-4.517 4.25048zm5.718 30.85386-.012.0041v-5.3298c-1.683 0-2.176-.8096-2.176-2.3403v-18.444h-7.122v19.5825c0 4.7312 2.125 6.907 6.148 6.907 1.9 0 3.02-.3346 3.15-.3754v.0086zm-44.781 0s-1.139.3795-3.163.3795c-4.023 0-6.148-2.1758-6.148-6.907v-9.0322c0-2.7704-1.632-4.63-4.301-4.63-3.378 0-4.681 3.0487-4.731 5.2751v15.0158h-7.072v-39.506601h7.072v16.382001c1.088-1.9102 3.213-3.4282 6.856-3.4282 6.642 0 9.311 4.6806 9.311 10.3985v8.3744c0 1.5307.493 2.3403 2.176 2.3403zm-30.374-6.6919s-4.136 7.122-13.826 7.122c-10.5 0-17.799-6.4769-17.799-19.3168 0-12.84002 6.527-19.43078 16.863-19.43078 13.434 0 14.636 11.25868 14.636 13.28268l-6.97 1.1386s-.215-7.83051-7.616-7.83051c-5.224 0-9.196 3.97211-9.196 12.84001 0 8.8678 5.06 12.7387 10.449 12.7387s9.032-5.1739 9.032-5.1739l4.402 4.63zm57.635 7.0714c1.961 0 3.099-.3795 3.099-.3795h.013v-5.3384c-1.683 0-2.176-.8096-2.176-2.3403v-18.444h-6.262v2.8337c-.088-.0719-.192-.1617-.312-.2652-1.056-.916-3.343-2.8974-7.253-2.8974-5.705 0-10.879 4.1999-10.879 13.4978s5.338 13.3839 10.449 13.3839c5.541 0 7.502-3.3649 7.995-4.4023v.0507c0 2.1252 1.569 4.301 5.326 4.301zm-11.436-20.6325c3.922 0 5.174 3.4915 5.225 4.4655l.012-.0126v5.5535c-.009-.0311-.03.0401-.074.1847-.255.8558-1.28 4.2808-5.1 4.2808-2.669 0-5.389-2.3403-5.389-7.2359 0-4.8957 2.277-7.236 5.326-7.236zm57.369 20.6325c2.011 0 3.15-.3795 3.15-.3795l.012-.0126v-5.3384c-1.682 0-2.176-.8096-2.176-2.3403v-8.3745c0-5.7179-2.669-10.3984-9.31-10.3984-4.845 0-7.021 2.2897-7.78 4.5161v-.0506c0-2.5554-1.961-4.4023-5.225-4.4023-2.011 0-3.099.3795-3.099.3795v5.3384c1.683 0 2.176.8729 2.176 2.3403v18.444h7.072v-15.0158c.05-2.2264 1.353-5.2751 4.731-5.2751 2.783 0 4.301 1.9102 4.301 4.7944v8.8678c0 4.7312 2.125 6.907 6.148 6.907z"
						fill="#1674b5"
					/>
				</g>
			</g>
		</svg>
	);
};
