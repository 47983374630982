// react
import { useContext } from 'react';

// types
import { Entity } from '../../../../@types';

// context
import { ContextUser } from '../../../../App.context';
import { ContextLoans, ContextProject } from '../Project.context';

// i18n
import { Trans } from 'react-i18next';

// router
import { Link as RouterLink } from 'react-router-dom';

// mui
import { Container, Grid, Link } from '@mui/material';

// pages
import { Details } from './Details/Details';
import { Loans } from './Loans/Loans';

// ui
import { Activity } from '../../../ui/Activity/Activity';
import { Tasks } from '../../../ui/Tasks/Tasks';

export const Overview = () => {
	// context
	const { project } = useContext(ContextProject);
	const { loans } = useContext(ContextLoans);
	const { user } = useContext(ContextUser);

	// vars
	const tasks = [];
	const userEntity = user?.entity ? (user.entity as Entity) : null;

	// define tasks
	if (project?.status === 'ENABLED') {
		// check if any loans are disabled
		for (let i = 0; i < loans.length; i++) {
			const loan = loans[i];

			if (loan.status === 'DISABLED') {
				tasks.push({
					content: (
						<Trans
							components={{ strong: <strong /> }}
							i18nKey="tasks.enableLoan"
							values={{ id: loan.idWbg }}
						/>
					),
					type: 'loan',
				});
			}
		}

		if (userEntity?.type === 'PIU') {
			tasks.push(
				{
					content: (
						<Trans
							components={{
								a: <Link component={RouterLink} to={`/project/${project.idWbg}/entities`} />,
							}}
							i18nKey="tasks.addEntities"
						/>
					),
					type: 'entity',
				},
				{
					content: (
						<Trans
							components={{
								a: <Link component={RouterLink} to={`/project/${project.idWbg}/suppliers`} />,
							}}
							i18nKey="tasks.addSuppliers"
						/>
					),
					type: 'supplier',
				},
				{
					content: (
						<Trans
							components={{
								a: <Link component={RouterLink} to={`/project/${project.idWbg}/beneficiaries`} />,
							}}
							i18nKey="tasks.addBeneficiaries"
						/>
					),
					type: 'individual',
				},
				{
					content: (
						<Trans
							components={{
								a: <Link component={RouterLink} to={`/project/${project.idWbg}/accounts`} />,
							}}
							i18nKey="tasks.addLocalAccounts"
						/>
					),
					type: 'account',
				}
			);
		}
	}

	if (!project) {
		return <></>;
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Details project={project} />
			</Grid>
			<Grid item xs={12}>
				<Loans project={project} />
			</Grid>
			<Grid item xs={12}>
				<Container maxWidth={false}>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={6}>
							<Tasks items={tasks} />
						</Grid>
						<Grid item xs={12} lg={6}>
							<Activity project={project} />
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</Grid>
	);
};
