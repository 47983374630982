// react
import { useContext, useEffect, useState } from 'react';

// azure
import { useIsAuthenticated } from '@azure/msal-react';

// router
import { useNavigate, useSearchParams } from 'react-router-dom';

// i18n
import { Trans, useTranslation } from 'react-i18next';

// hooks
import { useAuth } from '../../../hooks/useAuth';

// context
import { ContextUser } from '../../../App.context';

// mui
import { Alert, Grid, Typography, Button, Link } from '@mui/material';

// layouts
import { Gateway } from '../../layouts/Gateway/Gateway';

// img
import { LogoWorldBank } from '../../img/LogoWorldBank';

export const PageLogin = () => {
	// hooks
	const { login, logout } = useAuth();
	const isAuthenticated = useIsAuthenticated();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();

	// context
	const { user } = useContext(ContextUser);

	// state
	const [error, setError] = useState<string | null>(null);

	// params
	const paramError = searchParams.get('error');

	const handleLogin = async () => {
		await login();
	};

	// navigate to projects if authenticated
	useEffect(() => {
		if (paramError) {
			let msg = t('error.auth');

			if (paramError === 'deactivated') {
				msg = t('error.userDeactivated');
			} else if (paramError === 'expired') {
				msg = t('error.accessTokenExpired');
			}

			setError(msg);
		} else if (isAuthenticated && user) {
			navigate('/projects');
		}
	}, [isAuthenticated, navigate, paramError, t, user]);

	return (
		<Gateway title={t('login.title')}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="caption">{t('login.intro')}</Typography>
				</Grid>

				{error && (
					<Grid item xs={12}>
						<Alert icon={false} severity="error">
							{error}
						</Alert>
					</Grid>
				)}

				{paramError && ['auth', 'deactivated'].includes(paramError) ? (
					<Grid item xs={12}>
						<Button fullWidth={true} onClick={() => logout()} variant="contained">
							{t('logout')}
						</Button>
					</Grid>
				) : (
					<Grid item xs={12}>
						<Button fullWidth color="primary" onClick={handleLogin} variant="contained">
							{t('signIn')}
						</Button>
					</Grid>
				)}

				<Grid item xs={12}>
					<Typography color="brand.grey.500" variant="caption">
						<Trans
							components={{
								anchor: <Link href="https://www.worldbank.org/en/about/legal" target="_blank" />,
							}}
							i18nKey="login.tos"
						/>
					</Typography>
				</Grid>
				<Grid item>
					<LogoWorldBank style={{ width: 160 }} />
				</Grid>
			</Grid>
		</Gateway>
	);
};
