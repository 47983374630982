// l18n
import { TFunction } from 'i18next';

// mui
import { Typography } from '@mui/material';

// table
import { TableHeader } from '../../../ui/Table/Table';

// types
import { ExpenseType, Transaction, TransactionStatus, TransactionType } from '../../../../@types';

// ui
import { Amount } from '../../../ui/Amount/Amount';
import { ChipStatus } from '../../../ui/ChipStatus/ChipStatus';
import { Time } from '../../../ui/Time/Time';

// utils
import { translateTransactionType } from '../../../../utils/translations';

// props
export interface DocumentHeaderTableProps {
	t: TFunction<'translation', undefined>;
	transaction?: Transaction;
}

export const getTransactionsHeaders = ({ t }: DocumentHeaderTableProps): TableHeader[] => {
	const columns: TableHeader[] = [
		{
			label: t('type'),
			value: 'type',
			render: (type: TransactionType) => {
				return <Typography fontWeight={600}>{translateTransactionType(type, t)}</Typography>;
			},
		},
		{
			label: t('date'),
			value: 'created',
			render: (created: Date) => {
				return <Time date={new Date(created)} />;
			},
		},
		{
			label: t('expenseType'),
			value: 'expenseType',
			render: (expenseType: ExpenseType) => {
				return <Typography>{expenseType ? expenseType.name : t('na')}</Typography>;
			},
		},
		{
			label: t('status'),
			value: 'status',
			render: (status: TransactionStatus) => {
				return <ChipStatus status={status} />;
			},
		},
		{
			label: t('amount'),
			value: 'amount',
			render: (amount: number, header: TableHeader, transaction: Transaction) => {
				return <Amount currency={transaction.currency} value={amount} />;
			},
		},
	];

	return columns;
};
