// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// types
import { ErrorCause, Report } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';

// utils
import { downloadFile } from '../../../utils/files';

// props
export interface FormDownloadReportProps {
	onClose?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	report: Report | null;
}

export const FormDownloadReport = ({ onClose, setIsLoading, report }: FormDownloadReportProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);

	// state
	const [error, setError] = useState<string | null>(null);

	// vars
	const idForm = `form-download-report`;

	// handler
	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		console.log(entries);

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			// fetch report by type
			const fetchGetReport = await fetch(
				`${process.env.REACT_APP_API_URL}/reports?dateEnd=${entries.dateEnd}&dateStart=${entries.dateStart}&type=${report?.type}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				}
			);
			const resGetReport = await fetchGetReport.json();

			if (resGetReport.error) {
				throw new Error(resGetReport.error.message, {
					cause: {
						id: resGetReport.error.id,
					},
				});
			}

			// download report
			downloadFile({
				data: '',
				name: report?.fileName,
				type: 'application/pdf',
			});

			if (onClose) {
				onClose();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.reportDownloaded'),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'REPORT_INVALID_TYPE') {
					message = t('error.reportInvalidType');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	return (
		<form id={idForm} name="formDownloadReport" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Input
						id={`${idForm}-start-date`}
						label={t('startDate')}
						name="dateStart"
						required={true}
						type="date"
					/>
				</Grid>
				<Grid item xs={12}>
					<Input
						id={`${idForm}-end-date`}
						inputProps={{
							max: new Date().toISOString().split('T')[0],
						}}
						label={t('endDate')}
						name="dateEnd"
						required={true}
						type="date"
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
