// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// types
import { Account, AccountPayload, ErrorCause } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextProject } from '../../pages/Project/Project.context';
import { ContextBanner } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';

// ui
import { Loading } from '../../ui/Loading/Loading';

// props
export interface FormEditAccountProps {
	onClose?: () => void;
	refetchAccounts?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormEditAccount = ({
	onClose,
	refetchAccounts,
	setIsLoading,
}: FormEditAccountProps) => {
	// hooks
	const { t } = useTranslation();

	// search params
	const [searchParams] = useSearchParams();

	// params
	const id = searchParams.get('dialogAccount');

	// state
	const [account, setAccount] = useState<Account | null>(null);
	const [error, setError] = useState<string | null>(null);

	// context
	const { project } = useContext(ContextProject);
	const { setBanner } = useContext(ContextBanner);

	// vars
	const idForm = 'form-edit-account';

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// fetch account
	const { isLoading: isLoadingAccount } = useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/accounts/${id}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data && res.data.length) {
				setAccount(res.data[0]);
			}
		},
	});

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			if (!project) {
				throw new Error('The account needs to have a project ID associated with it.', {
					cause: {
						id: 'ACCOUNT_PROJECT_NON_EXISTENT',
					},
				});
			}

			const body: AccountPayload = {
				institution: String(entries.institution),
				name: String(entries.name),
				num: String(entries.num),
				project: project.id,
			};

			const fetchEditAccount = await fetch(`${process.env.REACT_APP_API_URL}/accounts/${id}`, {
				method: 'PATCH',
				body: JSON.stringify(body),
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});
			const resEditAccount = await fetchEditAccount.json();

			if (resEditAccount.error) {
				throw new Error(resEditAccount.error.message, {
					cause: {
						id: resEditAccount.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			// refetch accounts
			if (refetchAccounts) {
				refetchAccounts();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.accountUpdated', { name: entries.name }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'ACCOUNT_ID_NON_EXISTENT') {
					message = t('error.accountIdNonExistent');
				}
				if (cause.id === 'ACCOUNT_NON_EXISTENT') {
					message = t('error.accountNonExistent', { id: account?.id });
				}
				if (cause.id === 'ACCOUNT_PROJECT_NON_EXISTENT') {
					message = t('error.accountProjectNonExistent', { id: account?.id });
				}
				if (cause.id === 'ACCOUNT_UNAUTHORIZED_PATCH') {
					message = t('error.unauthorized');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	// content
	const renderContent = () => {
		if (isLoadingAccount) {
			return (
				<Grid item xs={12}>
					<Grid alignItems="center" container justifyContent="center">
						<Grid item>
							<Loading />
						</Grid>
					</Grid>
				</Grid>
			);
		}

		return (
			<>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={account?.name}
						id={`${idForm}-name`}
						label={t('name')}
						name="name"
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={account?.num}
						id={`${idForm}-number`}
						label={t('accountNumber')}
						name="num"
					/>
				</Grid>
				<Grid item xs={12}>
					<Input
						defaultValue={account?.institution}
						id={`${idForm}-financial-institution`}
						label={t('financialInstitution')}
						name="institution"
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</>
		);
	};
	return (
		<form id={`${idForm}`} name="formEditAccount" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				{renderContent()}
			</Grid>
		</form>
	);
};
