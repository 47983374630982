// types
import { Status } from '../../../@types';

// router
import { useTranslation } from 'react-i18next';

// mui
import { Chip, ChipProps, Typography } from '@mui/material';

// utils
import { translateStatus } from '../../../utils/translations';

// props
export interface ChipStatusProps extends ChipProps {
	status: Status;
}

export const ChipStatus = ({ color = 'default', status, ...props }: ChipStatusProps) => {
	// hooks
	const { t } = useTranslation();

	const colors: Record<ChipStatusProps['status'], ChipProps['color']> = {
		ACTIVE: 'success',
		COMPLETED: 'success',
		CREATED: 'success',
		DEACTIVATED: 'error',
		DISABLED: 'error',
		DONE: 'success',
		ENABLED: 'success',
		FAILED: 'error',
		INACTIVE: 'default',
		IN_PROGRESS: 'warning',
		INVITED: 'info',
		PENDING: 'default',
		STARTED: 'info',
		WAITING: 'warning',
	};

	if (!status) {
		return <Typography>{t('na')}</Typography>;
	}

	return (
		<Chip
			color={colors[status] || color}
			label={translateStatus(status, t)}
			size="small"
			variant="twoTone"
			{...props}
		/>
	);
};
