// react
import { useContext } from 'react';

// router
import { useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextAccounts, ContextProject } from '../Project.context';

// mui
import { Container, Grid, Typography } from '@mui/material';

// tables
import { TableAccounts } from '../../../tables/cards/TableAccounts/TableAccounts';

// dialogs
import { DialogAddAccount } from '../../../dialogs/DialogAddAccount/DialogAddAccount';

export const Accounts = () => {
	// state
	const [searchParams, setSearchParams] = useSearchParams();

	// context
	const { accounts, isLoadingAccounts, refetchAccounts } = useContext(ContextAccounts);
	const { isLoadingProject, isOnboarded: isProjectOnboarded } = useContext(ContextProject);

	// hooks
	const { t } = useTranslation();

	// vars
	const paramDialog = searchParams.get('dialog');
	const isLoading = isLoadingProject || isLoadingAccounts;

	// filter accounts by type
	const accountsDesignated = accounts.filter((a) => a.type === 'DESIGNATED');
	const accountsLocal = accounts.filter((a) => a.type === 'LOCAL');

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Container maxWidth={false}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h2">{t('designatedAccounts')}</Typography>
							</Grid>
							<Grid item xs={12}>
								<TableAccounts
									accounts={accountsDesignated}
									isLoading={isLoading}
									refetchAccounts={refetchAccounts}
									type="DESIGNATED"
								/>
							</Grid>
						</Grid>
					</Container>
				</Grid>
				{isProjectOnboarded && (
					<Grid item xs={12}>
						<Container maxWidth={false}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h2">{t('localAccounts')}</Typography>
								</Grid>
								<Grid item xs={12}>
									<TableAccounts
										accounts={accountsLocal}
										isLoading={isLoading}
										refetchAccounts={refetchAccounts}
										type="LOCAL"
									/>
								</Grid>
							</Grid>
						</Container>
					</Grid>
				)}
			</Grid>
			<DialogAddAccount
				isOpen={paramDialog === 'addAccount'}
				onClose={() => {
					setSearchParams((params) => {
						params.delete('dialog');
						params.delete('type');
						return params;
					});
				}}
			/>
		</>
	);
};
