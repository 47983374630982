// types
import { Loan, Payment, Transaction } from '../../../../@types';

// i18n
import { TFunction } from 'i18next';

// mui
import { Typography } from '@mui/material';

// table
import { BlockchainHash } from '../../../ui/BlockchainHash/BlockchainHash';
import { ChipStatus } from '../../../ui/ChipStatus/ChipStatus';
import { TableHeader } from '../../../ui/Table/Table';

// ui
import { Amount } from '../../../ui/Amount/Amount';
import { Time } from '../../../ui/Time/Time';

// utils
import { translateFinancingSource } from '../../../../utils/translations';

// props
export interface SourcesHeaderTableProps {
	t: TFunction<'translation', undefined>;
	transaction?: Transaction;
}

export const getSourcesHeaders = ({ t, transaction }: SourcesHeaderTableProps): TableHeader[] => {
	const columns: TableHeader[] = [
		{
			label: t('source'),
			value: 'loan',
			render: (loan: Loan, header: TableHeader, item: Payment) => {
				const source = (item.loan as Loan)?.idWbg || translateFinancingSource(item.source, t);
				return <Typography fontWeight={600}>{source}</Typography>;
			},
		},
		{
			label: t('recorded'),
			value: 'created',
			render: (created: Date) => {
				return <Time date={new Date(created)} />;
			},
		},
		{
			label: t('hash'),
			value: 'blkTransaction',
			render: (blkTransaction: Payment['blkTransaction']) => {
				return blkTransaction ? (
					<BlockchainHash hash={blkTransaction.id} />
				) : (
					<Typography>{t('na')}</Typography>
				);
			},
		},
		{
			label: t('status'),
			value: 'blkTransaction',
			render: (blkTransaction: Payment['blkTransaction']) => {
				return <ChipStatus status={blkTransaction.status} />;
			},
		},
		{
			label: transaction?.type === 'TRANSFER' ? t('amountSent') : t('amount'),
			value: 'amount',
			render: (amount: number, header: TableHeader, item: Payment) => {
				return <Amount currency={item.currency} value={amount} />;
			},
		},
	];

	if (transaction?.type === 'TRANSFER') {
		columns.push({
			label: t('amountReceived'),
			value: 'amountReceived',
			render: (amount: string, header: TableHeader, item: Payment) => {
				return (
					<Amount
						currency={item.currencyReceived || item.currency}
						value={item.amountReceived || item.amount}
					/>
				);
			},
		});
	}

	return columns;
};
