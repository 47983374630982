// types
import { Contract } from '../../../../@types';

// l18n
import { TFunction } from 'i18next';

// mui
import { Typography } from '@mui/material';

// table
import { TableHeader } from '../../../ui/Table/Table';

// ui
import { Amount } from '../../../ui/Amount/Amount';
import { Time } from '../../../ui/Time/Time';

// props
export interface GetHeadersProps {
	t: TFunction<'translation', undefined>;
}

export const getHeaders = ({ t }: GetHeadersProps): TableHeader[] => {
	return [
		{
			label: t('contract'),
			render: (num: string) => {
				return <Typography fontWeight={600}>{num}</Typography>;
			},
			value: 'num',
		},
		{
			label: t('amount'),
			value: 'amount',
			render: (amount: number, header: TableHeader, contract: Contract) => {
				return amount ? <Amount currency={contract.currency} value={amount} /> : t('na');
			},
		},
		{
			label: t('created'),
			render: (created: string) => {
				return <Time date={new Date(created)} />;
			},
			value: 'created',
		},
	];
};
