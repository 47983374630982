export const LogoFundsChainMark = ({ ...props }) => {
	return (
		<svg fill="none" viewBox="0 0 534 279" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="m366.705 277.307c-30.103 0-53.747-8.341-71.056-25.086s-25.964-40.576-25.964-71.495v-79.146c0-30.9179 8.655-54.7494 25.964-71.4942s41.015-25.0858 71.056-25.0858h167.26v61.7111h-153.212c-5.707 0-11.289 1.0035-16.808 3.0104s-10.473 4.7662-14.926 8.2783c-4.39 3.5747-7.965 7.7139-10.661 12.5429-2.697 4.829-4.077 10.0973-4.077 15.7413v69.801c0 5.707 1.317 10.913 4.077 15.742 2.696 4.829 6.208 9.031 10.661 12.543 4.39 3.574 9.345 6.334 14.926 8.278 5.519 2.007 11.163 2.948 16.808 2.948h153.212v61.711z"
				fill="#1575b3"
			/>
			<path
				d="m243.847 37.3611h-154.5288c-29.0996 0-52.6803 23.5806-52.6803 52.6802v188.9587"
				stroke="#fff"
				strokeMiterlimit="10"
				strokeWidth="73.1165"
			/>
			<path d="m158.43 109.984h-66.6032v66.603h66.6032z" fill="#117243" />
			<path d="m244.16 109.984h-66.602v66.603h66.602z" fill="#2cb486" />
		</svg>
	);
};
