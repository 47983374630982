// types
import { CsvRow } from '../@types';

// props
export interface CsvToArrProps {
	string: string;
	separator: string;
}

export const csvToArray = ({ string, separator }: CsvToArrProps) => {
	let array = string
		.trim()
		.split('\n')
		.filter((item) => {
			// filter out empty lines
			const itemWithoutCommas = item.trim().replaceAll(',', '');
			return itemWithoutCommas !== '';
		})
		.map((item) => item.split(separator));

	array = array.map((a) => {
		return a.map((b) => {
			return b.trim();
		});
	});

	return array;
};

export interface JsonObject {
	[x: string]: unknown;
}

export interface JsonToCsvParams {
	array: JsonObject[];
}

export const jsonToCsv = ({ array }: JsonToCsvParams) => {
	const replacer = (key: string, value: number | string) => {
		if (!value) {
			return '';
		}

		return value;
	};

	const keys: string[] = Object.keys(array[0]);

	const csv = [
		keys.join(','),
		...array.map((row) => keys.map((key) => JSON.stringify(row[key], replacer)).join(',')),
	].join('\r\n');

	return csv;
};

export interface ParseCsvProps {
	string: string;
	separator: string;
}

export const parseCsv = ({ string, separator }: ParseCsvProps) => {
	const array = csvToArray({ string, separator });
	const headers = array.shift() as string[];
	const rows: CsvRow[] = [];

	if (array) {
		// for each row
		for (let a = 0; a < array.length; a++) {
			const rowArray = array[a];
			const row: CsvRow = {};

			// for each header
			for (let h = 0; h < headers.length; h++) {
				const header = headers[h] as string;

				// add header and array value to row
				row[header] = rowArray[h];
			}

			// add row
			rows.push(row);
		}
	}

	return { rows, headers };
};

export interface DownloadFileParams {
	data: BlobPart;
	name?: string;
	type: BlobPropertyBag['type'];
}

export const downloadFile = ({ data, name, type }: DownloadFileParams) => {
	// create anchor
	const a = document.createElement('a');
	const url = window.URL.createObjectURL(new Blob([data], { type }));

	// set anchor attributes
	a.setAttribute('href', url);

	if (name) {
		a.setAttribute('download', name);
	}

	// append anchor
	document.body.appendChild(a);

	// click anchor
	a.click();

	// remove anchor
	window.URL.revokeObjectURL(url);
	a.remove();
};
