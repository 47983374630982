// react
import { useContext } from 'react';

// types
import { Account, ActionButton } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextUser } from '../../../../App.context';

// mui
import { Grid } from '@mui/material';

// cards
import { CardAccount } from '../../../cards/CardAccount/CardAccount';
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';

// props
export interface TableAccountsProps {
	accounts: Account[];
	isLoading?: boolean;
	refetchAccounts?: () => void;
	type?: Account['type'];
}

export const TableAccounts = ({
	accounts,
	isLoading,
	refetchAccounts,
	type = 'DESIGNATED',
}: TableAccountsProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { user } = useContext(ContextUser);

	// state
	const [searchParams, setSearchParams] = useSearchParams();

	// vars
	const emptyActions: ActionButton[] = [];

	if (!isLoading && user?.permissions?.account?.create) {
		emptyActions.push({
			children: t('addAccount'),
			onClick: () => {
				searchParams.set('dialog', 'addAccount');
				searchParams.set('type', type);
				setSearchParams(searchParams);
			},
			variant: 'contained' as ActionButton['variant'],
		});
	}

	return (
		<Grid container spacing={2}>
			{!isLoading && accounts.length > 0 ? (
				accounts.map((account, i) => {
					return (
						<Grid item key={`account-${type}-${i}`} xs={12}>
							<CardAccount account={account} refetchAccounts={refetchAccounts} />
						</Grid>
					);
				})
			) : (
				<Grid item xs={12}>
					<CardEmpty
						empty={{
							actions: emptyActions,
							content: {
								children:
									type === 'DESIGNATED'
										? t('empty.accounts.designated.content')
										: t('empty.accounts.local.content'),
							},

							title: { children: t('empty.accounts.title') },
						}}
						isLoading={isLoading}
					/>
				</Grid>
			)}
		</Grid>
	);
};
