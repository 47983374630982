// react
import { useContext } from 'react';

// context
import { ContextSuppliers } from '../../Project.context';

// mui
import { Container, Grid } from '@mui/material';

// tables
import { TableSuppliers } from '../../../../tables/cards/TableSuppliers/TableSuppliers';

export const Details = () => {
	// context
	const { isLoadingSuppliers, paginationSuppliers, suppliers, refetchSuppliers } =
		useContext(ContextSuppliers);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableSuppliers
						isLoading={isLoadingSuppliers}
						pagination={paginationSuppliers}
						refetchEntities={refetchSuppliers}
						suppliers={suppliers}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
