// react
import { useContext } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextContracts } from '../../../../pages/Project/Project.context';

// mui
import { Container, Grid, Typography } from '@mui/material';

// tables
import { TableContracts } from '../../../../tables/cards/TableContracts/TableContracts';

export const Details = () => {
	// hooks
	const { t } = useTranslation();

	// context
	const { contracts, isLoadingContracts, paginationContracts, refetchContracts } =
		useContext(ContextContracts);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h2">{t('contracts')}</Typography>
				</Grid>
				<Grid item xs={12}>
					<TableContracts
						contracts={contracts}
						isLoading={isLoadingContracts}
						pagination={paginationContracts}
						refetchContracts={refetchContracts}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
