// i18next
import { useTranslation } from 'react-i18next';

// material
import { Container, Grid } from '@mui/material';

// cards
import { CardKeyValue } from '../../../../cards/CardKeyValue/CardKeyValue';

// tables
import { ChartSankeyProps } from '../../../../charts/ChartSankey/ChartSankey';
import { formatCurrency } from '../../../../../utils/currency';

// props
export interface SummaryProps {
	dataSankey: ChartSankeyProps | null;
}

export const Summary = ({ dataSankey }: SummaryProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	let avg: string | number = t('na');
	let highest: string | number = t('na');
	let lowest: string | number = t('na');
	const totalNodes = dataSankey?.data?.nodes?.length || t('na');
	let totalTransactions: string | number = t('na');

	if (dataSankey?.data?.links?.length) {
		const currency = dataSankey.data.links[0].transactions[0].currency;

		let totalValue = 0;

		highest = 0;
		lowest = 0;
		totalTransactions = 0;

		for (let i = 0; i < dataSankey.data.links.length; i++) {
			const link = dataSankey.data.links[i];

			// add to total value
			totalValue += link.value;

			// add to total transactions
			totalTransactions += link.transactions.length;

			// set first link as lowest and highest
			if (i === 0) {
				highest = link.value;
				lowest = link.value;
			} else {
				// check highest
				if (link.value > highest) {
					highest = link.value;
				}

				// check lowest
				if (link.value < lowest) {
					lowest = link.value;
				}
			}
		}

		avg = formatCurrency({
			currency,
			notation: 'compact',
			value: Number(totalValue / dataSankey.data.links.length),
		});

		highest = formatCurrency({
			currency,
			notation: 'compact',
			value: Number(highest),
		});

		lowest = formatCurrency({
			currency,
			notation: 'compact',
			value: Number(lowest),
		});
	}

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={6} md={4} lg={6} xl={true}>
					<CardKeyValue
						label={t('entities')}
						IconCircleProps={{ type: 'entity' }}
						value={totalNodes}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={6} xl={true}>
					<CardKeyValue
						label={t('transactions')}
						IconCircleProps={{ type: 'payment' }}
						value={totalTransactions}
					/>
				</Grid>
				<Grid item xs={6} md={4} lg={true}>
					<CardKeyValue label={t('average')} IconCircleProps={{ type: 'payment' }} value={avg} />
				</Grid>
				<Grid item xs={6} md={6} lg={true}>
					<CardKeyValue label={t('lowest')} IconCircleProps={{ type: 'payment' }} value={lowest} />
				</Grid>
				<Grid item xs={12} md={6} lg={true}>
					<CardKeyValue
						label={t('highest')}
						IconCircleProps={{ type: 'payment' }}
						value={highest}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
