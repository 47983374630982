// types
import { Report, ReportType } from '../@types';

// i18
import { TFunction } from 'i18next';

export interface GetReportsParams {
	t: TFunction;
	type?: ReportType;
}

export const getReports = ({ t, type }: GetReportsParams) => {
	const reports: Report[] = [
		{
			content: t('reports.soe.content'),
			IconCircleProps: {
				type: 'payment',
			},
			title: t('soe'),
			type: 'SOE',
		},
		{
			content: t('reports.fundAllocation.content'),
			disabled: true,
			fileName: 'report-fund-allocation.pdf',
			IconCircleProps: {
				type: 'account',
			},
			title: t('fundAllocation'),
			type: 'FUND_ALLOCATION',
		},
		{
			content: t('reports.suppliersOverview.content'),
			disabled: true,
			fileName: 'report-suppliers.pdf',
			IconCircleProps: {
				type: 'supplier',
			},
			title: t('suppliersOverview'),
			type: 'SUPPLIERS',
		},
		{
			content: t('reports.beneficiariesOverview.content'),
			disabled: true,
			fileName: 'report-beneficiaries.pdf',
			IconCircleProps: {
				type: 'individual',
			},
			title: t('beneficiariesOverview'),
			type: 'BENEFICIARIES',
		},
	];

	if (type) {
		const report = reports.find((r) => r.type === type);

		if (report) {
			return [report];
		}
	}

	return reports;
};
