// i18n
import { useTranslation } from 'react-i18next';

// fields
import { Select } from '../Select/Select';

// mui
import { SelectProps as MuiSelectProps } from '@mui/material';

// types
import { Option } from '../../../@types';

export type SelectRowsPerPageProps = MuiSelectProps;

export const SelectRowsPerPage = ({ label, ...props }: SelectRowsPerPageProps) => {
	// hooks
	const { t } = useTranslation();

	const optionsRowsPerPage: Option[] = [
		{
			value: '5',
			label: '5',
		},
		{
			value: '10',
			label: '10',
		},

		{
			value: '25',
			label: '25',
		},
		{
			value: '50',
			label: '50',
		},
	];

	return (
		<Select
			aria-label={String(label) ? undefined : t('rowsPerPage')}
			defaultValue={'10'}
			hasNoneOption={false}
			hasSearchParam={true}
			label={label}
			name="limit"
			options={optionsRowsPerPage}
			{...props}
		/>
	);
};
