// react
import { ReactNode, useContext } from 'react';

// router
import { Link as RouterLink } from 'react-router-dom';

// types
import { Account, Project } from '../../../@types';

// context
import { ContextProject } from '../../pages/Project/Project.context';

// mui
import { Link, LinkProps, Typography } from '@mui/material';

// props
export interface LinkAccountProps extends LinkProps {
	account: Account;
	children?: ReactNode;
	project?: Project;
	[x: string]: unknown;
}

export const LinkAccount = ({ account, children, project, ...props }: LinkAccountProps) => {
	// context
	const { project: projectContext } = useContext(ContextProject);

	// vars
	const content = children || account.name;
	const projectId = project ? project.idWbg : projectContext?.idWbg;

	if (!account.id) {
		return <Typography fontWeight={600}>{account.name}</Typography>;
	}

	return (
		<Link
			component={RouterLink}
			fontWeight={600}
			sx={{ wordBreak: 'break-word' }}
			to={`/project/${projectId}/accounts/`}
			underline="hover"
			{...props}>
			{content}
		</Link>
	);
};
